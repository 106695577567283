import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Container,
} from "@mui/material";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  maxHeight: "80%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function JobOpeningForm({ onsubmit, onClose, existingJob, selectedBusiness }) {
  // Fetch businesses from Redux
  const { businesses } = useSelector((state) => state.business);
  const { paths } = useSelector((state) => state.career);

  // Local state for form
  const [formData, setFormData] = useState({
    business_id: existingJob?.business_id || selectedBusiness,
    career_path_id: existingJob?.career_path_id || "",
    title: existingJob?.title || "",
    pay_range: existingJob?.pay_range || "",
    work_hours: existingJob?.work_hours || "",
    full_time: existingJob?.full_time || false,
    job_description: existingJob?.job_description || "",
    core_responsibilities: existingJob?.core_responsibilities?.join("; ") || "",
    skills_required: existingJob?.skills_required?.join("; ") || "",
    job_stage: existingJob?.job_stage || 1,
    bonuses: existingJob?.bonuses || "",
    training_and_education: existingJob?.training_and_education || "",
    work_conditions: existingJob?.work_conditions || "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const processedFormData = {
      ...formData,
      core_responsibilities: formData.core_responsibilities
        .split(";")
        .map((resp) => resp.trim()),
      skills_required: formData.skills_required
        .split(";")
        .map((skill) => skill.trim()),
    };

    onsubmit(processedFormData);
    onClose();

    // Reset form only if creating a new job
    if (!existingJob) {
      setFormData({
        business_id: "",
        career_path_id: "",
        title: "",
        pay_range: "",
        work_hours: "",
        full_time: false,
        job_description: "",
        core_responsibilities: "",
        skills_required: "",
        job_stage: 1,
        bonuses: "",
        training_and_education: "",
        work_conditions: "",
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={style}>
        <Typography
          variant="h6"
          gutterBottom
          textAlign="center"
          color="primary"
        >
          {existingJob ? "Update Job Opening" : "Create Job Opening"}
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Job Title */}
          <TextField
            fullWidth
            label="Job Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Business Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="business-label">Link the Business</InputLabel>
            <Select
              labelId="business-label"
              name="business_id"
              value={formData.business_id}
              onChange={handleChange}
              required
              label="Business"
            >
              {businesses.map((business) => (
                <MenuItem key={business.id} value={business.id}>
                  {business.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Important Career Path Selection */}
          <FormControl
            fullWidth
            sx={{
              border: "2px solid",
              borderColor: "primary.main",
              borderRadius: 2,
              backgroundColor: "#f0f4ff", // Light blue background to make it stand out
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for emphasis
              padding: 1,
              mb: 2,
            }}
          >
            <InputLabel
              id="career_label"
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                fontSize: "1rem",
              }}
            >
              Select the Career Path That Aligns with This Job
            </InputLabel>
            <Select
              labelId="career_label"
              name="career_path_id"
              value={formData.career_path_id}
              onChange={handleChange}
              required
              label="Career_Path"
              sx={{
                fontWeight: "bold",
                borderRadius: "8px",
                backgroundColor: "white",
                fontSize: "0.9rem",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "#eef2ff", // Subtle hover effect
                },
              }}
            >
              {paths.map((path) => (
                <MenuItem key={path.career_path_id} value={path.career_path_id}>
                  {path.career_path_name} - {`${path.career_path_overview.substring(
                        0,
                        100
                      )}...`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Typography variant="caption" fontStyle={"italic"} sx={{with: '90%'}}>
            Selecting the right career path helps position your job opening
            accurately, ensuring it attracts the right talent and aligns with
            your business identity.
          </Typography> */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="job-stage-label">Job Stage</InputLabel>
            <Select
              labelId="job-stage-label"
              name="job_stage"
              value={formData.job_stage}
              onChange={handleChange}
              label="Job Stage"
              required
            >
              {paths
                .filter(
                  (path) => path.career_path_id === formData.career_path_id
                )
                .flatMap((path) =>
                  path.career_stages.map((stage) => (
                    <MenuItem
                      key={stage.stage_number}
                      value={stage.stage_number}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography>
                        {`Stage ${stage.stage_number} - ${stage.title} `}
                      </Typography>
                      <Typography>{`${stage.stage_description.substring(
                        0,
                        100
                      )}...`}</Typography>
                      <Typography>{`Pay Range: ${stage.pay_range}`}</Typography>
                    </MenuItem>
                  ))
                )}
            </Select>
          </FormControl>
          {/* Pay Range */}
          <TextField
            fullWidth
            label="Pay Range"
            name="pay_range"
            value={formData.pay_range}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Work Hours */}
          <TextField
            fullWidth
            label="Work Hours/Availability"
            name="work_hours"
            value={formData.work_hours}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Full Time Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="full-time-label">Full-Time</InputLabel>
            <Select
              labelId="full-time-label"
              name="full_time"
              value={formData.full_time ? "Yes" : "No"}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  full_time: e.target.value === "Yes",
                })
              }
              label="Full-Time"
              required
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>

          {/* Job Description */}
          <TextField
            fullWidth
            label="Job Description"
            name="job_description"
            value={formData.job_description}
            onChange={handleChange}
            required
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          {/* Core Responsibilities */}
          <TextField
            fullWidth
            label="Core Responsibilities (separated by ;)"
            name="core_responsibilities"
            value={formData.core_responsibilities}
            onChange={handleChange}
            helperText={
              "(Clearly list the key duties of this role—this helps attract candidates who are confident in their skills and ready to contribute effectively.)"
            }
            required
            sx={{ mb: 2 }}
          />

          {/* Skills Required */}
          <TextField
            fullWidth
            label="Skills Required (separated by ;)"
            name="skills_required"
            value={formData.skills_required}
            onChange={handleChange}
            helperText={
              "(List the essential skills needed for this role—being specific helps attract qualified candidates who are well-prepared and the right fit for your business.)"
            }
            required
            sx={{ mb: 2 }}
          />

          {/* Bonuses */}
          <TextField
            fullWidth
            label="What job-specific incentives are available for this position?"
            name="bonuses"
            value={formData.bonuses}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />

          {/* Training and Education */}
          <TextField
            fullWidth
            label="Are you offering any training and education?"
            name="training_and_education"
            value={formData.training_and_education}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />

          {/* Work Conditions */}
          <TextField
            fullWidth
            label="Describe what the work condition is like"
            name="work_conditions"
            value={formData.work_conditions}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, py: 1 }}
          >
            {existingJob ? "Update Job Opening" : "Create Job Opening"}
          </Button>
        </form>
      </Box>
    </Container>
  );
}
