import {
  Box,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";

export default function OwnerInfo({ owner }) {
  const { businesses } = useSelector((state) => state.business);
  const { openings } = useSelector((state) => state.job);
  const { first_name, last_name, id, created_at } = owner;

  // Format the owner's created_at date
  const formattedOwnerDate = created_at
    ? format(new Date(created_at), "MM/dd/yyyy")
    : "Date not available";

  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 1,
        mb:1,
      }}
    >
      {/* Owner and Business Information */}
      <Grid item xs={12} md={6}>
        <Box sx={{
            boxShadow: 1,
            borderRadius: 2,
            backgroundColor: "white",
            p:2
        }}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
            {first_name} {last_name}
          </Typography>
          <Typography variant="caption" color="textSecondary" sx={{ mb: 2 }}>
            Joined on: {formattedOwnerDate}
          </Typography>

          <Divider sx={{ mb: 2 }} />

          {businesses
            .filter((business) => business.owner_id === id)
            .map((business) => {
              const formattedBusinessDate = business.created_at
                ? format(new Date(business.created_at), "MM/dd/yyyy")
                : "Date not available";

              return (
                <Box
                  key={business.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    boxShadow: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "#333" }}
                  >
                    {business.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    {business.address}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    {business.description}
                  </Typography>
                  <Typography variant="caption" sx={{ color: "#999" }}>
                    Created At: {formattedBusinessDate}
                  </Typography>
                </Box>
              );
            })}

          {businesses.filter((business) => business.owner_id === id).length ===
            0 && (
            <Typography variant="body2" color="textSecondary">
              No businesses found for this owner.
            </Typography>
          )}
        </Box>
      </Grid>

     
      {/* Job Openings Information */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            maxHeight: "400px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            boxShadow: 1,
            borderRadius: 2,
            backgroundColor: "background.main",
            p: 2,
          }}
        >
          {/* Fixed Title */}
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
          >
            Job Openings
          </Typography>

          {/* Scrollable Content */}
          <Box
            sx={{
              flex: 1,
              overflowY: "auto", // Enable vertical scrolling
              width: "100%",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar for Webkit browsers (Chrome, Safari)
              },
              "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
              "scrollbar-width": "none", // Hide scrollbar for Firefox
            }}
          >
            {businesses
              .filter((business) => business.owner_id === id)
              .map((business) =>
                openings
                  .filter((opening) => opening.business_id === business.id)
                  .map((opening) => (
                    <Box
                      key={opening.id}
                      sx={{
                        mb: 2,
                        p: 2,
                        backgroundColor: "#ffffff",
                        borderRadius: 1,
                        boxShadow: 1,
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold", color: "#333" }}
                      >
                        {opening.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#666" }}>
                        {opening.job_description}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#666" }}>
                        {opening.pay_range
                          ? `${opening.pay_range}`
                          : "Not specified"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: opening.active ? "primary.main" : "red" }}
                      >
                        Active: {opening.active ? "Yes" : "No"}
                      </Typography>
                    </Box>
                  ))
              )}

            {businesses
              .filter((business) => business.owner_id === id)
              .every(
                (business) =>
                  openings.filter(
                    (opening) => opening.business_id === business.id
                  ).length === 0
              ) && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                No job openings found for the businesses.
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
