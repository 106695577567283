import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createJobApplication } from "../../../redux/slices/applicationSlice";
import { useNavigate } from "react-router-dom";

export default function ApplicationForm({ job_opening_id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedOpening = useSelector((state) => state.job.selectedOpening);
  const { error } = useSelector((state) => state.application);
  const [formData, setFormData] = useState({
    answer_1: "",
    answer_2: "",
    answer_3: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    window.gtag("event", "apply_job", {
      event_category: "engagement",
      event_label: "Job Application",
      value: 1,
    });
    const applicationData = {
      job_opening_id,
      ...formData,
    };

    dispatch(createJobApplication(applicationData));

    if (!error) {
      navigate("/user/dashboard");
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ mt: 4, minHeight: "500px", textAlign: "left" }}
    >
      <Typography
        sx={{
          textAlign: "left",
          mb: 4,
          fontWeight: "bold",
          color: "primary.main",
        }}
      >
        Application: (2/2)
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Questions From {selectedOpening.business_name}
        </Typography>
        <Typography>{selectedOpening.question_1}</Typography>
        <TextField
          label="Your answer 1"
          name="answer_1"
          value={formData.answer_1}
          onChange={handleChange}
          fullWidth
          required
        />

        <Typography>{selectedOpening.question_2}</Typography>
        <TextField
          label="Your answer 2"
          name="answer_2"
          value={formData.answer_2}
          onChange={handleChange}
          fullWidth
          required
        />

        <Typography>{selectedOpening.question_3}</Typography>
        <TextField
          label="Your answer 3"
          name="answer_3"
          value={formData.answer_3}
          onChange={handleChange}
          fullWidth
          required
        />

        {/* Error handling */}
        {error && <Alert severity="error">{error.message}</Alert>}

        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </Container>
  );
}
