import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

import { Link } from "react-scroll";
import { WavingHandOutlined } from "@mui/icons-material";

const pages = ["Solution", "About Us", "Contact"];
const settings = ["Post Job😎!", "Explore Careers", "Login"];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);

    if (setting === "Explore Careers") {
      navigate("/careers");
    } else if (setting === "Login") {
      navigate("/login");
    } else if (setting === "Post Job!") {
      navigate("/connect_businesses");
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ bgcolor: "white", minHeight: { xs: "50px", md: "80px" } }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* logo when it's full screen size */}

          <CloudCircleIcon
            fontSize="large"
            sx={{
              display: { xs: "none", md: "flex" },
              color: "primary.main",
              mb:1,
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            // href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              p: 1,
              display: { xs: "none", md: "flex" },
              fontFamily:
                "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive", // Custom font family
              fontSize: "32px",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "primary.main",
              textDecoration: "none",
              height: "80px",
            }}
          >
            Cloudens
          </Typography>

          {/* menu for navigation bar when it's mobile size */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon color="primary.main" />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem key={page}>
                  <Link
                    to={page}
                    smooth={true}
                    duration={500}
                    sx={{ textAlign: "center" }}
                    onClick={handleCloseNavMenu}
                  >
                    {page}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* logo when it's in mobile screen size */}
          <CloudCircleIcon
            sx={{
              display: { xs: "flex", md: "none" },
              color: "primary.main",
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily:
                "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive", // Custom font family
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "primary.main",
              textDecoration: "none",
              height: "100%",
              p: 1,
              // bgcolor:'red'
            }}
          >
            Cloudens
          </Typography>

          {/* navigation bar when it's a full screeen */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "primary.main", display: "block" }}
              >
                <Link to={page} smooth={true} duration={500}>
                  {page}
                </Link>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Join Us 😎">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                <AccountCircleIcon
                  fontSize="large"
                  sx={{ color: "primary.main" }}
                />
                <WavingHandOutlined
                  sx={{
                    color: "primary.main",
                    ml: 0,
                    fontSize: "20px",
                    animation: "wave 2s infinite", // Apply waving animation
                    "@keyframes wave": {
                      "0%": { transform: "rotate(0deg)" }, // Start position
                      "10%": { transform: "rotate(14deg)" }, // Waving up
                      "20%": { transform: "rotate(-8deg)" }, // Waving down
                      "30%": { transform: "rotate(14deg)" }, // Waving up again
                      "40%": { transform: "rotate(-4deg)" }, // Waving down slightly
                      "50%": { transform: "rotate(10deg)" }, // A final wave up
                      "60%": { transform: "rotate(0deg)" }, // Return to initial
                    },
                    transformOrigin: "70% 70%", // Set the origin of the waving motion
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                  sx={{
                    height: "50px",
                    width: "100%",
                    transition:
                      "background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
                    "&:hover": {
                      backgroundColor: "primary.main", // Background color on hover
                      color: "white", // Text color on hover
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow effect on hover
                      transform: "scale(1.02)", // Slight scale effect on hover for a modern look
                    },
                  }}
                >
                  {setting}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
