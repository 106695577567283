import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Box, Button, Container, TextField, Typography } from "@mui/material";

const SignUpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state for form input
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "prospect",
  });

  // Access loading and error states from Redux
  const { loading, error } = useSelector((state) => state.auth);

  // Handle form input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.gtag("event", "sign_up", {
      event_category: "engagement",
      event_label: "Prospect Signup",
      value: 1,
    });
    dispatch(signUpUser(formData));
    // navigate('/careers');
  };

  return (
    <Container
      className="signup-page"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: 3,
      }}
    >
      <AssignmentIndIcon sx={{ fontSize: "2rem", color: "primary.main" }} />
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Sign Up
      </Typography>
      <Typography sx={{ fontWeight: "bold", mb: 3 }}>
        Discover exciting career opportunities!
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          maxWidth: 400,
        }}
      >
        {/* First Name */}
        <TextField
          label="First Name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Last Name */}
        <TextField
          label="Last Name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Email */}
        <TextField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Password */}
        <TextField
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          fullWidth
          helperText="Password must be at least 8 characters long and include one letter, one number, and one special character."
        />
        {/* Role Dropdown */}
        {/* <FormControl fullWidth>
          <InputLabel id="role-label">You are</InputLabel>
          <Select
            label='role'
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <MenuItem value="business_owner">Business Owner</MenuItem>
            <MenuItem value="prospect">Prospect</MenuItem>
          </Select>
        </FormControl> */}

        {/* Error handling */}
        {error && (
          <Typography color="error" variant="body2">
            {error.message || ""}
          </Typography>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ py: 1.5 }} // Larger button
        >
          {loading ? "Signing up..." : "Sign Up"}
        </Button>
      </Box>

      <Typography
        variant="body2"
        sx={{ mt: 2, cursor: "pointer" }}
        color="primary"
        onClick={() => {
          navigate("/login");
        }}
      >
        Already have an account? Log in
      </Typography>
    </Container>
  );
};
export default SignUpPage;
