import * as React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import careerPathImage from "../../../images/career_path_card_image.jpg";
import { useDispatch, useSelector } from "react-redux";
// import { updateUserProfile } from "../../../redux/slices/userSlice";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";

export default function CareerPathCard() {
  const selectedPath = useSelector((state) => state.career.selectedPath);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!selectedPath || !selectedPath.career_path_name) {
    return null; // Don't render the card if the selectedPath is empty or invalid
  }

  const handleOnClick = () => {
    // dispatch(
    //   updateUserProfile({
    //     industry_id: selectedPath.industry_id,
    //     career_path_id: selectedPath.career_path_id,
    //   })
    // );
    navigate(`/careers/${selectedPath.career_path_id}`);
  };

  return (
    <Card
      sx={{
        maxWidth: { xs: "100%", md: "70%" },
        mx: "auto",
        m: { xs: 2, sm: 2 },
        backgroundColor: "background.default",
        borderRadius: 2,
        boxShadow: 3,
        height: "100%",
      }}
    >
      <img
        src={careerPathImage}
        alt="Career Path"
        style={{
          width: "100%",
          height: "120px",
          objectFit: "cover",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      />
      <CardContent>
        <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
          Career Path
        </Typography>
        <Typography variant="h6" component="div" fontWeight={"bold"}>
          {selectedPath.career_path_name}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
          {selectedPath.career_path_overview}
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: { xs: "none", sm: "inline" } }}>
          <Typography
            variant="body2"
            sx={{ mt: 2, mb: 2 }} 
            textAlign="left"
          >
            <ApartmentIcon></ApartmentIcon> Industry: "
            {selectedPath.industry_name}" - {selectedPath.industry_description}
          </Typography>

          <Typography
            variant="body2"
            sx={{ mt: 2 }}
            // fontWeight={"bold"}
            textAlign="left"
          >
            <TrendingUpIcon> </TrendingUpIcon> Outlook:{" "}
            {selectedPath.career_path_outlook}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, fontWeight: 'bold' }} textAlign="left">
            <FlagCircleIcon /> Your Career:{" "}
            {selectedPath.alignment_with_career_goals.length > 80
              ? `${selectedPath.alignment_with_career_goals.substring(
                  0,
                  80
                )}...`
              : selectedPath.alignment_with_career_goals}
          </Typography>

          <Divider sx={{ my: 2 }} />
        </Box>

        <Box sx={{ mt: 2, mb: 2, display: { xs: "none", sm: "inline" } }}>
          <Typography variant="h6" textAlign="left" gutterBottom>
            Inspiring Journeys
          </Typography>
          {selectedPath.success_stories_business_owners?.map((story, index) => (
            <Box
              key={index}
              sx={{
                borderRadius: 2,
                // bgcolor: "background.main",
                color: "primary.main",
                outline: 2,
                p: 2,
                mb: 2,
                boxShadow: 2,
              }}
            >
              <Typography variant="body2" textAlign="left" fontWeight={"bold"}>
                "{story}"
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small" sx={{ ml: 2, mb: 2 }} onClick={handleOnClick}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
