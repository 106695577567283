import React from "react";
import { Typography, Box } from "@mui/material";

const LegalStatement = ({ businessName }) => {
  return (
    <Box mt={'40px'}>
      <Typography variant="body2" sx={{ fontWeight: "bold", mb: 2 }}>
        Equal Employment Opportunity Statement
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {businessName} is an equal opportunity employer committed to diversity
        and inclusion in the workplace. We prohibit discrimination and
        harassment of any kind based on race, color, sex, religion, sexual
        orientation, gender identity, national origin, disability, age, or any
        other legally protected status. 
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {businessName} complies with the Immigration Reform and Control Act
        (IRCA) and verifies employment eligibility through Form I-9. We will
        provide reasonable accommodations for individuals with disabilities in
        accordance with applicable laws.
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: "bold", mt: 3, mb: 2 }}>
        E-Verify Notice
      </Typography>
      <Typography variant="body2">
        If required by law or contractual obligation, {businessName} will use
        E-Verify to confirm employment eligibility of new hires in accordance
        with federal and state regulations. E-Verify compares information from
        your Form I-9 to federal records to confirm your work authorization.
      </Typography>
    </Box>
  );
};

export default LegalStatement;
