import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthNavigationbar from "../../components/AuthNavigationBar";
import Footer from "../../components/Landing/Footer";
import ApplicationForm from "../../components/Application/JobApplication/ApplicationForm";
import {
  Box,
} from "@mui/material";
import CompleteProfileForm from "../../components/Prospect/JobApplication/CompleteProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../redux/slices/userSlice";
import { getJobOpeningsForCareerPath, setSelectedOpening } from "../../redux/slices/jobSlice";
import { clearApplication } from "../../redux/slices/applicationSlice";

const Application = () => {
  const dispatch = useDispatch();
  const { career_path_id, jobId } = useParams();
  const { profile } = useSelector((state) => state.user);
  const { openings } = useSelector(
    (state) => state.job
  );

  useEffect(() => {
    
    if (openings.length === 0) {
      dispatch(getUserProfile());
      dispatch(getJobOpeningsForCareerPath(career_path_id)).then(() => {
        dispatch(setSelectedOpening(jobId));
        dispatch(clearApplication());
      });
    }
  }, [dispatch, career_path_id, jobId, openings.length]);

  return (
    <>
      <AuthNavigationbar />
      <Box sx={{
        mt: 12,
      }}>

       
        <CompleteProfileForm profile={profile} />

        <ApplicationForm job_opening_id={jobId} />
        <Footer />

    
      </Box>
    </>
  );
};

export default Application;
