import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import AuthNavigationbar from "../../components/AuthNavigationBar";
import Footer from "../../components/Landing/Footer";

import {
  Box,
  Container,
  Typography,
  Grid,
  Fab,
  Modal,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import {
  getAllBusinesses,
  getBusinessOwners,
} from "../../redux/slices/businessSlice";

import {
  getAllJobOpening,
} from "../../redux/slices/jobSlice";

import {
  createCareerPath,
  getCareerPaths,
} from "../../redux/slices/careerSlice";
import CareerList from "../../components/Careers/AdminControl/CareerList";
import CareerPathForm from "../../components/Careers/AdminControl/CareerPathForm";
import Owners from "../../components/Business/AdminControl/Owners";
import { getAllProspects } from "../../redux/slices/userSlice";
import Prospects from "../../components/Prospect/AdminControl/Prospects";
import { getAllApplications } from "../../redux/slices/applicationSlice";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.auth);
  const { paths } = useSelector((state) => state.career);
  const { businessOwners } = useSelector((state) => state.business);
  const { users } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState(1);
  const handleOpen = (num) => {
    setOpen(true);
    setFormType(num);
  };
  const handleClose = (num) => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getCareerPaths()); //Used
    dispatch(getBusinessOwners()); //used
    dispatch(getAllBusinesses()); //used
    dispatch(getAllJobOpening()); //used
    dispatch(getAllProspects()); //used
    dispatch(getAllApplications()) //used
  }, [dispatch]);

  // const handleLogOut = () => {
  //   dispatch(logoutUser());
  // };

  const handleSubmit = (formData) => {
    if (formType === 1) {
      dispatch(createCareerPath(formData));
    } else {
      return;
    }
  };

  // if(loading){
  //   return (
  //     <Typography>Loading...</Typography>
  //   )
  // }

  return (
    <>
      <AuthNavigationbar />
      <Box
        sx={{
          position: "relative",
          top: { xs: "40px", sm: "70px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "space-between",
          bgcolor: "background.main",
        }}
      >
        <Box
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mr: 2, // Apply margin-right
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive", // Custom font family
                fontSize: {
                  sm: "1rem",
                  md: "2rem",
                },
              }}
            >
              Welcome To Admin Dashboard
            </Typography>
          </Container>
        </Box>

        {/* Grid Layout for Business, Job Openings, and Applicants */}
        <Container sx={{ my:4}}>
          <Grid container spacing={4}>
            {/* Business Section */}
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  width: "100%",
                  maxHeight: {
                    xs: "400px",
                    md: "600px",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Career Paths(s)
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleOpen(1)}
                  >
                    <AddIcon />
                  </Fab>
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for Webkit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                  }}
                >
                  {paths && paths.length > 0 ? (
                    <CareerList />
                  ) : (
                    " No Career Path added yet."
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  width: "100%",
                  maxHeight: {
                    xs: "400px",
                    md: "600px",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Business Owners
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for Webkit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                  }}
                >
                  {businessOwners && businessOwners.length > 0 ? (
                    <Owners />
                  ) : (
                    " No Business Owner added yet."
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  width: "100%",
                  maxHeight: {
                    xs: "400px",
                    md: "600px",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Prospects
                </Typography>
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for Webkit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                  }}
                >
                  {users && users.length > 0 ? (
                    <Prospects />
                  ) : (
                    " No Prospect added yet."
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Modal open={open} onClose={handleClose}>
          {formType === 1 ? (
            <CareerPathForm onsubmit={handleSubmit} onClose={handleClose} />
          ) : (
            <>Hello</>
          )}
        </Modal>
      </Box>
      {/* <Button variant="contained" onClick={handleLogOut} disabled={loading} sx={{m:4}}>
        Sign out
      </Button> */}
      <Footer />
    </>
  );
};

export default AdminDashboard;
