import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import AdminCareerPathCard from "./AdminCareerPathCard";

export default function CareerList() {
  const { paths } = useSelector((state) => state.career);

  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      // bgcolor: red[500],
    }}
  >
      {paths.map((path) => (
        <AdminCareerPathCard path={path} key={path.id}/>
      ))}
    </Container>
  );
}
