import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../../redux/slices/authSlice";
import {
  clearProfile,
  deleteUserProfile,
  getUserApplications,
  getUserProfile,
} from "../../redux/slices/userSlice";
import AuthNavigationbar from "../../components/AuthNavigationBar";
import Footer from "../../components/Landing/Footer";
import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import ProfileDisplay from "../../components/Prospect/UserDashboardControl/ProfileDisplay";
import ProfileEditForm from "../../components/Prospect/UserDashboardControl/ProfileEditForm";
import ProspectApplicationsList from "../../components/Prospect/UserDashboardControl/ProspectApplicationsList";
import { clearPaths, getCareerPaths } from "../../redux/slices/careerSlice";
import { clearApplication } from "../../redux/slices/applicationSlice";
import { clearOpenings } from "../../redux/slices/jobSlice";

const UserDashboard = () => {
  const dispatch = useDispatch();
  
  // console.log(path);
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState(1);
  const handleOpen = (num) => {
    if (num === 3) {
      setOpen(true);
    }
    setFormType(num);
  };
  const handleClose = (num) => {
    setOpen(false);
    setFormType(1);
  };

  // const handleLogOut = () => {
  //   dispatch(logoutUser());
  // };

  const handleDelete = () => {
    dispatch(deleteUserProfile());
    dispatch(clearState());
    dispatch(clearProfile());
    dispatch(clearPaths());
    dispatch(clearApplication());
    dispatch(clearOpenings());
  };

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getUserApplications());
    dispatch(getCareerPaths());
  }, [dispatch]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <AuthNavigationbar />
      <Box
        sx={{
          position: "relative",
          top: { xs: "40px", sm: "70px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "space-between",
          bgcolor: "background.main",
          pt: 2,
        }}
      >
        <Box
          sx={{
            // backgroundColor: grey[100],
            padding: 2,
            borderRadius: 2,
            top: 10,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mr: 2, // Apply margin-right
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive", // Custom font family
                fontSize: {
                  sm: "1rem",
                  md: "2rem",
                },
              }}
            >
              Welcome To Your Dashboard!
            </Typography>
          </Container>
        </Box>

        <Container sx={{ mt: 2, mb: 2 }}>
          <Grid container spacing={4}>
            {/* Business Section */}
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  minHeight: {
                    xs: "400px",
                    md: "600px",
                  },
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar for Webkit browsers
                  },
                  "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                  "scrollbar-width": "none", // Hide scrollbar for Firefox
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Your Profile
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleOpen(2)}
                    disabled={formType === 2}
                  >
                    <EditIcon />
                  </Fab>
                </Typography>

                {formType === 1 ? (
                  <ProfileDisplay />
                ) : formType === 2 ? (
                  <ProfileEditForm onClose={handleClose} />
                ) : (
                  <ProfileDisplay />
                )}

                {formType === 2 ? (
                  <></>
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleOpen(3)}
                    sx={{ mt: 2 }}
                  >
                    Delete User
                  </Button>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  minHeight: {
                    xs: "400px",
                    md: "400px",
                  },
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar for Webkit browsers
                  },
                  "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                  "scrollbar-width": "none", // Hide scrollbar for Firefox
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Your Application(s)
                </Typography>

                <ProspectApplicationsList />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="red"
            >
              Are you sure you want to delete your account?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              We value your presence and would hate to see you go. If there's
              anything we can do to improve your experience, please let us know.
              Remember, deleting your account is permanent and you will lose all
              your data.
            </Typography>
            <Button
              color="error"
              sx={{ mt: 2, width: "100%" }}
              onClick={handleDelete}
            >
              Yes I want to delete my account
            </Button>
          </Box>
        </Modal>
        <Footer />
      </Box>
    </>
  );
};

export default UserDashboard;
