import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteJobOpening,
  updateJobOpening,
} from "../../../redux/slices/jobSlice";
import ApplicationQuestions from "../../Application/BusinessDashboard/ApplicationQuestions";

import JobOpeningForm from "./JobOpeningForm";

export default function JobOpeningCard({ opening }) {
  const dispatch = useDispatch();

  const {
    id,
    title,
    business_name,
    pay_range,
    created_at,
    active,
    question_1,
    question_2,
    question_3,
  } = opening;

  const questions = question_1 ? [question_1, question_2, question_3] : [];

  const [isActive, setIsActive] = useState(active);
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState(1);
  const handleOpen = (num) => {
    setOpen(true);
    setFormType(num);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = async () => {
    // Toggle the state and immediately use the correct value. This methods deals with async effect of React.
    const newActiveState = !isActive;
    setIsActive(newActiveState);

    const formData = { active: newActiveState };
    editJobOpening(formData);
  };

  const removeJobOpening = () => {
    dispatch(deleteJobOpening(id));
  };
  const editJobOpening = (formData) => {
    const editFormData = { id: id, ...formData };
    dispatch(updateJobOpening(editFormData));
  };

  return (
    <Box
    sx={{
      position: "relative",
      bgcolor: isActive ? "white" : grey[100],
      padding: 2, 
      mb: 2,
      borderRadius: 2, 
      boxShadow: isActive ? 3 : 1, 
      transition: "all 0.3s ease-in-out",
      border: `1px solid ${isActive ? "primary.main" : grey[300]}`,
      "&:hover": {
        boxShadow: 4, 
      },
    }}
  >
    {/* Edit and Remove Icons */}
    <EditIcon
      sx={{
        fontSize: "1.2rem", 
        position: "absolute",
        top: 8, 
        left: 8,
        color: "grey",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
        },
      }}
      onClick={() => handleOpen(1)}
    />
    <RemoveCircleIcon
      sx={{
        fontSize: "1.2rem", 
        position: "absolute",
        top: 8,
        right: 8,
        color: "grey",
        cursor: "pointer",
        "&:hover": {
          color: "red",
        },
      }}
      onClick={() => removeJobOpening()}
    />
  
    {/* Job Details with Avatar */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 2, 
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          sx={{
            bgcolor: isActive ? "primary.main" : grey[400],
            width: 32, 
            height: 32, 
            mr: 1, 
          }}
        >
          {title[0]}
        </Avatar>
        <Box>
          <Typography
            variant="subtitle2" 
            sx={{ fontWeight: "bold", textAlign: "left" }}
          >
            {title}
          </Typography>
          <Typography variant="caption" sx={{ textAlign: "left" }}>
            {business_name || "Business Name"}
          </Typography>
        </Box>
      </Box>
    </Box>
  
    {/* Active/Inactive Toggle */}
    <Box sx={{ textAlign: "center", mb: 1 }}> 
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isActive}
              onChange={handleToggle}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "primary.main",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "primary.main",
                },
              }}
            />
          }
          label={
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "0.9rem", 
                color: isActive ? "primary.main" : "grey",
              }}
            >
              {isActive ? "Active" : "Inactive"}
            </Typography>
          }
        />
      </FormGroup>
    </Box>
  
    {/* Job Opening Edit Modal */}
    <Modal open={open} onClose={handleClose}>
      {formType === 1 ? (
        <JobOpeningForm
          existingJob={opening}
          onsubmit={editJobOpening}
          onClose={handleClose}
        />
      ) : (
        <ApplicationQuestions
          jobOpeningId={id}
          onClose={handleClose}
          questions={questions}
        />
      )}
    </Modal>
  
    {/* Footer with Pay and Date */}
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1, 
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: "bold" }}>
        {pay_range || "Salary not provided"}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {format(new Date(created_at), "MMMM dd, yyyy")}
      </Typography>
    </Box>
  
    {/* Add/Edit Questions Button */}
    <Button
      size="small"
      variant="contained"
      disabled={!isActive}
      onClick={() => handleOpen(2)}
      fullWidth
      sx={{ mt: 1 }} 
    >
      {questions.length > 0 ? "Edit Questions" : "Add Questions*"}
    </Button>
  </Box>
  
  );
}
