import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:4001/api"; // For local dev


//Gets all job openings -Admin
export const getAllJobOpening = createAsyncThunk(
  "job/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/admin/jobs`,{
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets job openings for a career path
export const getJobOpeningsForCareerPath = createAsyncThunk(
  "job/getOpeningsForCareerPath",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/career_paths/${id}/job_openings`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets job openings for a business owner
export const getJobOpeningsForOwner = createAsyncThunk(
  "job/getOpeningsForOwner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/account/owner/job_openings`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Creates a new job opening
export const createJobOpening = createAsyncThunk(
  "job/createOpening",
  async (formData, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await axios.post(
       `${apiBaseUrl}/account/owner/job_openings`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Updates a job opening
export const updateJobOpening = createAsyncThunk(
  "job/editOpening",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/account/owner/job_openings`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Deletes a job opening
export const deleteJobOpening = createAsyncThunk(
  "job/deleteOpening",
  async (job_opening_id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${apiBaseUrl}/account/owner/job_openings/${job_opening_id}`,
    
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Add application questions to a job opening
export const addJobQuestions = createAsyncThunk(
  "job/addQuestions",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/account/owner/job_openings/questions`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// JobOpening slice
const jobSlice = createSlice({
  name: "job",
  initialState: {
    openings: [],
    loading: false,
    error: null,
    selectedOpening: {}
  },
  reducers: {
    clearOpenings: (state) => {
      state.openings = [];
      state.selectedOpening = {};
    },
    setSelectedOpening: (state, action) =>{
      // console.log(action.payload);
      state.selectedOpening = state.openings.find((opening) => opening.id === action.payload);
    }
  },
  extraReducers: (builder) => {
    // Handle getAll
    builder
      .addCase(getAllJobOpening.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllJobOpening.fulfilled, (state, { payload }) => {
        state.openings = payload;
        state.loading = false;
      })
      .addCase(getAllJobOpening.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle getJobOpeningsForCareerPath
    builder
      .addCase(getJobOpeningsForCareerPath.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobOpeningsForCareerPath.fulfilled, (state, { payload }) => {
        state.openings = payload; // Store the fetched career paths
        state.loading = false;
      })
      .addCase(getJobOpeningsForCareerPath.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    //Handle job openings for a business owner
    builder
      .addCase(getJobOpeningsForOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobOpeningsForOwner.fulfilled, (state, { payload }) => {
        state.openings = payload; // Store the fetched career paths
        state.loading = false;
      })
      .addCase(getJobOpeningsForOwner.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    //Handle create job opening
    builder
      .addCase(createJobOpening.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createJobOpening.fulfilled, (state, { payload }) => {
        state.openings = [...state.openings, payload];
        state.loading = false;
        toast.success("New Job Has Been Added!", {
          position: "top-center",
          icon: "✅",
        });
      })
      .addCase(createJobOpening.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    
    //Handle delete job opening
    builder
      .addCase(deleteJobOpening.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteJobOpening.fulfilled, (state, { meta }) => {
        state.openings = state.openings.filter(
          (opening) => opening.id !== meta.arg
        );
        state.loading = false;
      })
      .addCase(deleteJobOpening.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    //Handle update job opening
    builder
      .addCase(updateJobOpening.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateJobOpening.fulfilled, (state, { payload }) => {
        const index = state.openings.findIndex(
          (opening) => opening.id === payload.id
        );

        if (index !== -1) {
          state.openings[index] = { ...state.openings[index], ...payload };
        }

        state.loading = false;
      })
      .addCase(updateJobOpening.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    
    //Handle add job questions
    builder
      .addCase(addJobQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addJobQuestions.fulfilled, (state, { payload }) => {
        const index = state.openings.findIndex(
          (opening) => opening.id === payload.id
        );

        if (index !== -1) {
          state.openings[index] = { ...state.openings[index], ...payload };
        }

        state.loading = false;
      })
      .addCase(addJobQuestions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearOpenings, setSelectedOpening } = jobSlice.actions;
export default jobSlice.reducer;
