import React from "react";
import {
  Card,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { format } from "date-fns";

export default function ProspectApplicationCard({ application }) {
  const {
    created_at,
    application_status,
    business_name,
    business_address,
    job_title,
    pay_range,
    work_hours,
    full_time,
    answer_1,
    answer_2,
    answer_3,
  } = application;

  const formattedDate = created_at
    ? format(new Date(created_at), "MMMM dd, yyyy")
    : "Date not available";

  return (
    <Card
      sx={{
        height: "auto",
        marginBottom: "16px",
        boxShadow: 4,
        borderRadius: 2,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {/* Left Side: Title, Business Name, Address, and Date */}
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column" },
            justifyContent: "center",
            alignItems: "flex-start",
            color: "white",
            textAlign: "left",
            backgroundColor:
              application_status === "declined"
                ? "grey.500" // Gray color for declined status
                : application_status === "accepted"
                ? "secondary.main" // Yellow color for accepted status
                : "primary.main", // Default color (or any other you prefer for other statuses)
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" sx={{ mr: 2 }} gutterBottom>
            {job_title}
          </Typography>
          <Typography
            variant="caption"
            sx={{ display: { xs: "none", sm: "inline" } }}
          >
            {business_name}
          </Typography>
          <Typography
            variant="caption"
            sx={{ display: { xs: "none", sm: "inline" }, mb: 2 }}
            gutterBottom
          >
            {business_address}
          </Typography>
          <Typography variant="caption">Applied on: {formattedDate}</Typography>
        </Grid>

        {/* Right Side: Pay, Hours, Full-Time, and Application Answers */}
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            padding: 1,
          }}
        >
          <Grid container spacing={2}>
            {/* Pay Range */}
            <Grid item xs={4}>
              <Typography variant="subtitle2">Pay Range</Typography>
              <Typography variant="caption">{pay_range}</Typography>
            </Grid>

            {/* Work Hours */}
            <Grid item xs={4}>
              <Typography variant="subtitle2">Work Hours</Typography>
              <Typography variant="caption">{work_hours}</Typography>
            </Grid>

            {/* Full-Time Status */}
            <Grid item xs={4}>
              <Typography variant="subtitle2">Full-Time</Typography>
              <Typography variant="caption">
                {full_time ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Application Answers */}
          <Box
            textAlign={"left"}
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 1,
            }}
          >
            <Typography variant="caption">
              <strong>Q1:</strong> {answer_1}
            </Typography>
            <Typography variant="caption">
              <strong>Q2:</strong> {answer_2}
            </Typography>
            <Typography variant="caption">
              <strong>Q3:</strong> {answer_3}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
