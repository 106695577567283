import * as React from "react";
import { Box, Drawer, Button, Divider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBusiness } from "../../../redux/slices/businessSlice";


export default function BusinessDrawer({ business }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedBusiness(business));
  }, [dispatch, business]);

  const [open, setOpen] = React.useState(false);
  const selectedBusiness = useSelector(
    (state) => state.business.selectedBusiness
  );

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerContent = (
    <Box
      sx={{
        width: { xs: "300px", sm: "400px" },
        p: 2,
        role: "presentation",
      }}
      onClick={toggleDrawer(false)}
    >
      {selectedBusiness ? (
        <Box>
          {/* Business Name */}
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {selectedBusiness.name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.address}
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            {selectedBusiness.description || "No description available"}
          </Typography>


          <Divider sx={{ my: 2 }} />

          {/* Industry Detail */}
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            {selectedBusiness.industry_name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.industry_description}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Business Hours */}
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Business Hours
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.business_hour || "Not available"}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Years of Operation */}
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Years of Operation
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.years_of_operation} years
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Customer Reviews */}
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Customer Reviews
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.customer_reviews?.map((review) => (
              <Typography variant="body2" textAlign="left" sx={{ mb: 2 }}>
                {review}
              </Typography>
            )) || "No reviews available"}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Employee Testimonials */}
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Employee Testimonials
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.employee_testimonials?.map((employee) => (
              <Typography variant="body2" textAlign="left" sx={{ mb: 2 }}>
                {employee}
              </Typography>
            )) || "No testimonials available"}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Perks */}
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Perks
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedBusiness.perks || "Not available"}
          </Typography>

          <Divider sx={{ my: 2 }} />
        </Box>
      ) : (
        <Typography variant="h6">No business selected.</Typography>
      )}
    </Box>
  );

  return (
    <div>
      <Button size='small' onClick={toggleDrawer(true)} variant="contained" color="primary">
        Open Details
      </Button>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {DrawerContent}
      </Drawer>
    </div>
  );
}
