import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:4001/api"; // For local dev

//Gets all applications -Admin
export const getAllApplications = createAsyncThunk(
  "application/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/admin/applications`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

//Gets applications for business
export const getJobApplications = createAsyncThunk(
  "application/getApplications",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/account/owner/applications`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Business owner updates Application Status
export const updateApplication = createAsyncThunk(
  "application/updateApplication",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/account/owner/applications`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Prospects Creates a new application
export const createJobApplication = createAsyncThunk(
  "application/submitApplication",
  async (formData, { rejectWithValue }) => {
    // console.log(formData);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/account/user/applications/${formData.job_opening_id}`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// JobOpening slice
const applicationSlice = createSlice({
  name: "application",
  initialState: {
    applications: [],
    loading: false,
    error: '',
  },
  reducers: {
    clearApplication: (state) => {
      state.applications = [];
      state.loading = false;
      state.error = '';

    },
  },
  extraReducers: (builder) => {
    //Gets all job applications 
    builder
      .addCase(getAllApplications.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getAllApplications.fulfilled, (state, { payload }) => {
        state.applications = payload;
        state.loading = false;
      })
      .addCase(getAllApplications.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Gets job applications applied to the business
    builder
      .addCase(getJobApplications.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getJobApplications.fulfilled, (state, { payload }) => {
        state.applications = payload;
        state.loading = false;
      })
      .addCase(getJobApplications.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Creates a new application
    builder
      .addCase(createJobApplication.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(createJobApplication.fulfilled, (state, { payload }) => {
        state.applications = [...state.applications, payload];
        state.loading = false;
        toast.success("Application Submitted Successfully!", {
          position: "top-center",
          icon: "✅",
        });
      })
      .addCase(createJobApplication.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error("You can only apply once. Please go to your dashboard!", {
          position: "top-center",
          icon: "❌",
        });
        
      });
    //Updates Application
    builder
      .addCase(updateApplication.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(updateApplication.fulfilled, (state, { payload }) => {
        const index = state.applications.findIndex(
          (application) => application.application_id === payload.id
        );

        if (index !== -1) {
          state.applications[index] = { ...state.applications[index], ...payload };
        }
        state.loading = false;
      })
      .addCase(updateApplication.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearApplication } = applicationSlice.actions;
export default applicationSlice.reducer;
