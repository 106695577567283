import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  IconButton,
  Modal,
  Button,
} from "@mui/material";
import { RemoveCircle, Edit as EditIcon } from "@mui/icons-material";
import CareerPathForm from "./CareerPathForm";
import { useDispatch } from "react-redux";
import {
  createStage,
  deleteCareerPath,
  updateCareerPath,
} from "../../../redux/slices/careerSlice";
import StagesList from "../CareerStages/StagesList";
import StageForm from "../CareerStages/StageForm";

export default function AdminCareerPathCard({ path }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = React.useState(1);

  const handleOpen = (num) => {
    setOpen(true);
    setFormType(num);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeCareerPath = () => {
    dispatch(deleteCareerPath(path.career_path_id));
  };

  const handleSubmit = (formData) => {
    if (formType === 1) {
      const updatedFormData = { id: path.career_path_id, ...formData };
      // console.log(updatedFormData);
      dispatch(updateCareerPath(updatedFormData));
    } else {
      dispatch(createStage(formData));
    }
  };

  if (!path || !path.career_path_name) {
    return null; // Don't render the card if the path is empty or invalid
  }

  return (
    <Box sx={{ maxWidth: "100%", mt: 4 }}>
      <Grid container spacing={4}>
        {/* Career Path Card */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "background.default",
              borderRadius: 2,
              boxShadow: 3,
              height: "100%",
            }}
          >
            <CardContent>
              <Box sx={{ position: "relative" }}>
                {/* Edit Icon */}
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    left: 8,
                    color: "black",
                    "&:hover": {
                      color: "green",
                    },
                  }}
                  onClick={() => handleOpen(1)}
                >
                  <EditIcon />
                </IconButton>

                {/* Remove Icon */}
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "black",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                  onClick={() => removeCareerPath()}
                >
                  <RemoveCircle />
                </IconButton>
              </Box>

              {/* Career Path Name */}
              <Typography variant="h6" component="div" sx={{ mt: 3 }}>
                {path.career_path_name}
              </Typography>
              {/* Career Path Details */}
              <Typography variant="body2" sx={{ mt: 2 }} textAlign="left">
                {path.career_path_overview}
              </Typography>

              <Box textAlign={"left"} marginBottom={4}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Industry: {path.industry_name}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                  {path.industry_description}
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Outlook: {path.career_path_outlook}
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Alignment with Career Goals:{" "}
                  {path.alignment_with_career_goals}
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                  Success Stories:{" "}
                  {path.success_stories_business_owners.join(", ")}
                </Typography>
              </Box>

              <Button variant="contained" onClick={() => handleOpen(2)}>
                +Career Stage
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Career Stages Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2, bgcolor:'background.main' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Career Stages
            </Typography>
            <Box
              sx={{
                maxHeight: "600px", 
                overflowY: "auto", // Enable vertical scrolling
                "&::-webkit-scrollbar": {
                  display: "none", // Hide scrollbar for Webkit browsers (Chrome, Safari)
                },
                "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                "scrollbar-width": "none", // Hide scrollbar for Firefox
    
              }}
            >
              <StagesList path={path} />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        {formType === 1 ? (
          <CareerPathForm
            onsubmit={handleSubmit}
            onClose={handleClose}
            path={path}
          />
        ) : (
          <StageForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            career_path_id={path.career_path_id}
          />
        )}
      </Modal>
    </Box>
  );
}
