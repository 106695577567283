import React from "react";
import { Box, Typography, Container, Grid2 } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#333", 
        color: "#fff", 
        py: 4, 
        mt: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid2 container spacing={4} justifyContent="center" flexDirection={"column"}>
          {/* Section 1: About */}
          <Grid2 item>
            <Typography variant="h6" gutterBottom>
              Cloudens
            </Typography>
            <Typography variant="body1">
              {/* Our mission is to create seamless hiring
              experience for employers and real career growth for job seekers in small businesses. */}
              Empower career mobility and growth by providing real opportunities and fostering meaningful, sustainable success for small businesses
            </Typography>
          </Grid2>

        

          {/* Section 3: Contact Info */}
          <Grid2 item sx={{height: "100px"}}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1">
             guruemjobboard@gmail.com
            </Typography>
            {/* <Typography variant="body2">Phone: +1 (XXX) XXX-7890</Typography>
            <Typography variant="body2">
              Address: 
            </Typography> */}
          </Grid2>
        </Grid2>

        {/* Bottom section: Copyright */}
        <Box
          sx={{
            textAlign: "center",
            mt: 4,
            borderTop: "1px solid #555",
            pt: 2,
          }}
        >
          <Typography variant="body1">
            &copy; {new Date().getFullYear()} Guruem inc. All rights
            reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
