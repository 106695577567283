import { Link } from 'react-router-dom';
import ResponsiveAppBar from '../components/Landing/NavigationBar';
import Hero from '../components/Landing/Hero';
import Footer from '../components/Landing/Footer';

const LandingPage = () => {
  return (
    <div>
      <ResponsiveAppBar />
      <Hero />
      <Footer />
    </div>
  );
};

export default LandingPage;
