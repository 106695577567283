import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import JobList from "../../Jobs/Career_Detail_Page/JobList";
import { useNavigate } from "react-router-dom";

export default function CareerStageStepper() {
  const { selectedPath } = useSelector((state) => state.career);
  const steps = selectedPath.career_stages || [];
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid container spacing={2}>
      {/* Career Stages Stepper */}
      <Grid item xs={12} sm={5}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 2, minHeight: "400px" }}>
          {/* <Typography variant="h5" sx={{ mb: 2 }}>
              Career Path Stages
            </Typography> */}
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.stage_number}>
                <StepLabel
                  optional={
                    index === steps.length - 1 ? (
                      <Typography variant="caption"></Typography>
                    ) : null
                  }
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {`Stage ${step.stage_number}: ${step.title}`}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography variant="body2" textAlign={"left"}>
                    {step.stage_description}
                  </Typography>

                  <Typography
                    variant="body2"
                    textAlign={"left"}
                    fontWeight={"bold"}
                    sx={{ mt: 4 }}
                  >
                    Required Skills:
                  </Typography>
                  {step.skills_required.map((skill) => (
                    <Typography variant="body2" textAlign={"left"}>
                      - {skill}
                    </Typography>
                  ))}

                  <Typography
                    variant="body2"
                    textAlign={"left"}
                    sx={{ mt: 4, fontWeight: "bold" }}
                  >
                    Pay Range: {step.pay_range}
                  </Typography>
                  <Box sx={{ mb: 2, mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? "Jump In" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "primary.main",
                }}
              >
                Congratulations! 😎  You’re now ready to become a{" "}
                {selectedPath.career_path_name}!
              </Typography>
              <Typography sx={{ mt: 1, mb: 2 }}>
                We always knew you had it in you! We're excited to see you take
                this final step with us. Reach out and let’s make your career
                dreams a reality!
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mt: 1,
                  py: 1,
                  px: 3,
                  fontSize: "1rem",
                  fontWeight: "bold",
                  backgroundColor: "primary.main",
                  color: "white",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                }}
                onClick={()=>navigate('/start/your/dream')}
              >
                Contact Guruem
              </Button>

              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Paper>
      </Grid>

      {/* Job List Display */}
      <Grid item xs={12} sm={7}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 2,
            minHeight: "200px",
            width: { xs: "100%", sm: "100%" },
          }}
        >
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
            Career-Progression for Stage {activeStep + 1}
          </Typography>
          {/* Display the job listings related to the current step */}
          <JobList stage={activeStep + 1} />
        </Paper>
      </Grid>
    </Grid>
  );
}
