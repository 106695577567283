import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearState } from "../../redux/slices/authSlice";
import AuthNavigationbar from "../../components/AuthNavigationBar";
import Footer from "../../components/Landing/Footer";

import {
  Box,
  Container,
  Typography,
  Grid,
  Fab,
  Modal,
  Button,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import {
  clearOwnerProfile,
  createBusiness,
  deleteBusinessOwnerProfile,
  getBusinessesByOwner,
  getBusinessOwnerProfile,
} from "../../redux/slices/businessSlice";
import BusinessForm from "../../components/Business/BusinessDashboard/businessForm";
import BusinessList from "../../components/Business/BusinessDashboard/BusinessList";

import {
  clearOpenings,
  createJobOpening,
  getJobOpeningsForOwner,
} from "../../redux/slices/jobSlice";
import JobOpeningForm from "../../components/Jobs/BusinessDashboardControl/JobOpeningForm";
import JobOpeningList from "../../components/Jobs/BusinessDashboardControl/JobOpeningList";

import {
  clearApplication,
  getJobApplications,
} from "../../redux/slices/applicationSlice";
import ApplicationList from "../../components/Application/BusinessDashboard/ApplicationList";
import { clearPaths, getCareerPaths } from "../../redux/slices/careerSlice";

const BusinessDashboard = () => {
  const dispatch = useDispatch();
  const { profile, businesses } = useSelector((state) => state.business);
  const { first_name } = profile;
  const { openings } = useSelector((state) => state.job);
  const { applications } = useSelector((state) => state.application);

  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState(1);
  const handleOpen = (num) => {
    setOpen(true);
    setFormType(num);
  };
  const handleClose = (num) => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getJobOpeningsForOwner());
    dispatch(getBusinessOwnerProfile());
    dispatch(getBusinessesByOwner());
    dispatch(getJobApplications());
    dispatch(getCareerPaths());
  }, [dispatch]);

  // const handleLogOut = () => {
  //   dispatch(logoutUser());
  // };

  const handleDelete = () => {
    dispatch(deleteBusinessOwnerProfile());
    dispatch(clearOwnerProfile());
    dispatch(clearState());
    dispatch(clearPaths());
    dispatch(clearApplication());
    dispatch(clearOpenings());
  };

  const handleSubmit = (formData) => {
    if (formType === 1) {
      dispatch(createBusiness(formData));
    } else {
      dispatch(createJobOpening(formData));
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <AuthNavigationbar />
      <Box
        sx={{
          position: "relative",
          top: { xs: "40px", sm: "70px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "space-between",
          bgcolor: "background.main",
        }}
      >
        <Box
          sx={{
            padding: 2,
            mt: 1,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive", // Custom font family
                fontSize: {
                  sm: "1rem",
                  md: "2rem",
                },
              }}
            >
              Welcome
            </Typography>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: {
                    sm: "1rem",
                    md: "2rem",
                  },
                }}
              >
                {`${first_name}!`}
              </Typography>
            </Box>
          </Container>
        </Box>

        <Container sx={{ mt: 0 }}>
          <Grid container spacing={2}>
            {/* Business Section */}
            <Grid item xs={12} md={3.5}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  width: "100%",
                  maxHeight: {
                    xs: "400px",
                    md: "500px",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Your Business(s)
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleOpen(1)}
                  >
                    <AddIcon />
                  </Fab>
                </Typography>

                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for Webkit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                  }}
                >
                  {businesses && businesses.length > 0 ? (
                    <BusinessList />
                  ) : (
                    " No business yet. Add your first!"
                  )}
                </Box>
              </Box>
           
            </Grid>

            {/* Job Openings Section */}
            <Grid item xs={12} md={3.5}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  maxHeight: {
                    xs: "400px",
                    md: "500px",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  Job Openings
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleOpen(2)}
                  >
                    <AddIcon />
                  </Fab>
                </Typography>

                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for Webkit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                  }}
                >
                  {openings && openings.length > 0 ? (
                    <JobOpeningList />
                  ) : (
                    " No job openings yet. Add your first!"
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Applicants Section */}
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  padding: 2,
                  boxShadow: 3,
                  maxHeight: {
                    xs: "400px",
                    md: "500px",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    textAlign: "left",
                  }}
                >
                  Applicants
                </Typography>

                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar for Webkit browsers
                    },
                    "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
                    "scrollbar-width": "none", // Hide scrollbar for Firefox
                  }}
                >
                  {applications && applications.length > 0 ? (
                    <ApplicationList />
                  ) : (
                    " No applicants yet. "
                  )}
                </Box>
              </Box>   
              
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleOpen(3)}
                sx={{ mt: 2 }}
              >
                Delete Account
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Footer />
        <Modal open={open} onClose={handleClose}>
          {formType === 1 ? (
            <BusinessForm onsubmit={handleSubmit} onClose={handleClose} />
          ) : formType === 2 ? (
            <JobOpeningForm onsubmit={handleSubmit} onClose={handleClose} />
          ) : formType === 3 ? (
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                color="red"
              >
                Are you sure you want to delete your account?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                We value your presence and would hate to see you go. If there's
                anything we can do to improve your experience, please let us
                know. Remember, deleting your account is permanent and you will
                lose all your data.
              </Typography>
              <Button
                color="error"
                sx={{ mt: 2, width: "100%" }}
                onClick={handleDelete}
              >
                Yes, I want to delete my account
              </Button>
            </Box>
          ) : null}
        </Modal>
      </Box>
    </>
  );
};

export default BusinessDashboard;
