import React, { useEffect } from "react";
import { Typography, Box, Divider, Avatar, Container } from "@mui/material";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthNavigationbar from "../../components/AuthNavigationBar";
import Footer from "../../components/Landing/Footer";

import JobDrawer from "../../components/Jobs/Job_Detail_Page/Job_Business_Drawer";
import JobDetails from "../../components/Jobs/Job_Detail_Page/JobDetails";
import {
  getJobOpeningsForCareerPath,
  setSelectedOpening,
} from "../../redux/slices/jobSlice";

import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
import LoadingComponent from "../../components/loadingSpinner";

const JobPage = () => {
  const dispatch = useDispatch();
  const { career_path_id, jobId } = useParams();

  const { selectedOpening, openings, loading } = useSelector(
    (state) => state.job
  );

  useEffect(() => {
    if (openings.length === 0) {
      dispatch(getJobOpeningsForCareerPath(career_path_id)).then(() => {
        dispatch(setSelectedOpening(jobId));
      });
    }
  }, [dispatch, career_path_id, jobId, openings.length]);

  if (loading || !selectedOpening) {
    return (
      <LoadingComponent />
    );
  }

  const { business_name, address } = selectedOpening;

  return (
    <>
      <AuthNavigationbar />
      <Box
        sx={{
          position: "relative",
          top: "70px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
          backgroundColor: "white",
          // boxShadow: 3,
          p: { xs: 2, sm: 4 },
          m: 0,
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {/* Business Info Section */}
        <Box
          sx={{
            backgroundColor: "background.main",
            padding: 2,
            borderRadius: 2,
            marginBottom: 2,
            width: { xs: "100%", sm: "400px" },
            boxShadow: 2,
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-even",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={business_name}
              src="/static/images/avatar/1.jpg"
              sx={{ width: 48, height: 48, bgcolor: "primary.main", mr: 2 }}
            />
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{ color: "primary.main" }}
              >
                {business_name}
              </Typography>
              <Typography
                variant="body2"
                textAlign="left"
                color="textSecondary"
                gutterBottom
              >
                {address}
              </Typography>

              <JobDrawer />
            </Box>
          </Container>

          <Divider sx={{ my: 2 }} />

          <Container>
            {/* Customer Reviews */}
            <Typography
              variant="subtitle2"
              textAlign="left"
              sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
            >
              <ThreePOutlinedIcon></ThreePOutlinedIcon> Customer Reviews
            </Typography>

            {selectedOpening.customer_reviews?.map((review) => (
              <Typography variant="body2" textAlign="left" sx={{ mb: 2 }}>
                {review}
              </Typography>
            )) || "No reviews available"}

            <Divider sx={{ my: 2 }} />

            {/* Employee Testimonials */}
            <Typography
              variant="subtitle2"
              textAlign="left"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              <PortraitOutlinedIcon></PortraitOutlinedIcon> Employee
              Testimonials
            </Typography>

            {selectedOpening.employee_testimonials?.map((employee) => (
              <Typography variant="body2" textAlign="left" sx={{ mb: 2 }}>
                {employee}
              </Typography>
            )) || "No testimonials available"}

            <Divider sx={{ my: 2 }} />
          </Container>
        </Box>

        {/* Job Details */}
        <JobDetails />
      </Box>
      <Footer />
    </>
  );
};

export default JobPage;
