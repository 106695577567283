import React from "react";
import {
  Card,
  Typography,
  Grid,
} from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";


export default function StageCard({ career_stage }) {
  const {
    stage_number,
    pay_range,
    skills_required,
    title,
    stage_description
  } = career_stage;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: grey[50],
        mb: 2,
        p: 1.5,
        boxShadow: 1,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={1}>
        {/* Stage Number and Title */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ color: blue[500], fontWeight: 600 }}>
            Stage {stage_number}: {title}
          </Typography>
        </Grid>

        {/* Stage Description */}
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: grey[700], fontSize: "0.85rem" }}>
            {stage_description}
          </Typography>
        </Grid>

        {/* Skills Required */}
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
            Skills Required:
          </Typography>
          {skills_required?.length > 0 ? (
            skills_required.map((skill, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ color: blue[700], fontSize: "0.8rem" }}
              >
                - {skill}
              </Typography>
            ))
          ) : (
            <Typography variant="body2" sx={{ color: grey[600], fontSize: "0.8rem" }}>
              No skills provided
            </Typography>
          )}
        </Grid>

        {/* Pay Range */}
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
            Pay Range:
          </Typography>
          <Typography variant="body2" sx={{ color: green[600], fontSize: "0.8rem", fontWeight: 600 }}>
            {pay_range ? `${pay_range}` : "Not specified"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
