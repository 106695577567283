import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:4001/api"; // For local dev

//Get All Businesses -Admin
export const getAllBusinesses = createAsyncThunk(
  "business/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/admin/businesses`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets Business Owner's Profile
export const getBusinessOwnerProfile = createAsyncThunk(
  "business/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/account/owner`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Deletes Owner's Account
export const deleteBusinessOwnerProfile = createAsyncThunk(
  "business/deleteBusinessOwner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${apiBaseUrl}/account/owner`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Creates New Business
export const createBusiness = createAsyncThunk(
  "business/createBusiness",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/account/owner/businesses`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Deletes Business
export const deleteBusiness = createAsyncThunk(
  "business/deleteBusiness",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${apiBaseUrl}/account/owner/businesses/${id}`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Updates Business
export const updateBusiness = createAsyncThunk(
  "business/updateBusiness",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/account/owner/businesses`,
        formData,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets Businesses For the Owner
export const getBusinessesByOwner = createAsyncThunk(
  "business/getBusinesses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/account/owner/businesses`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets all business owners -Admin
export const getBusinessOwners = createAsyncThunk(
  "business/getOwners",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/admin/owners`,
        {
          withCredentials: true, // Send token in cookie
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  profile: {
    first_name: "",
    last_name: "",
    email: "",
  },
  businessOwners: [],
  businesses: [],
  loading: false,
  error: null,
  selectedBusiness: {},
};

// User slice
const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    clearOwnerProfile: (state) => {
      state.profile = {};
      state.businesses = [];
      state.selectedBusiness = {};
      state.businessOwners = [];
    },
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle getAll
    builder
      .addCase(getAllBusinesses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllBusinesses.fulfilled, (state, { payload }) => {
        state.businesses = payload;
        state.loading = false;
      })
      .addCase(getAllBusinesses.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    // Handle getUserProfile
    builder
      .addCase(getBusinessOwnerProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusinessOwnerProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.loading = false;
      })
      .addCase(getBusinessOwnerProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Deletes Account
    builder
      .addCase(deleteBusinessOwnerProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBusinessOwnerProfile.fulfilled, (state, { meta }) => {
        state.profile = {};
        state.loading = false;
        toast.error("Your Profile Has Been Deleted", {
          position: "top-center",
          icon: '👋'
        });
      })
      .addCase(deleteBusinessOwnerProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Gets businesses for the owner
    builder
      .addCase(getBusinessesByOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusinessesByOwner.fulfilled, (state, { payload }) => {
        state.businesses = payload;
        state.loading = false;
      })
      .addCase(getBusinessesByOwner.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Creates new business
    builder
      .addCase(createBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBusiness.fulfilled, (state, { payload }) => {
        state.businesses = [...state.businesses, payload];
        state.loading = false;
        toast.success("New Business Has Been Added!", {
          position: "top-center",
          icon: "✅",
        });
      })
      .addCase(createBusiness.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Deletes A Business
    builder
      .addCase(deleteBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBusiness.fulfilled, (state, { meta }) => {
        state.businesses = state.businesses.filter(
          (business) => business.id !== meta.arg
        );
        state.loading = false;
      })
      .addCase(deleteBusiness.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Updates Business
    builder
      .addCase(updateBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBusiness.fulfilled, (state, { payload }) => {
        const index = state.businesses.findIndex(
          (business) => business.id === payload.id
        );

        if (index !== -1) {
          state.businesses[index] = { ...state.businesses[index], ...payload };
        }

        state.loading = false;
      })
      .addCase(updateBusiness.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //Gets All Business Owners' Profile
    builder 
      .addCase(getBusinessOwners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusinessOwners.fulfilled, (state, { payload }) => {
        state.businessOwners = payload;
        state.loading = false;
      })
      .addCase(getBusinessOwners.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearOwnerProfile, setSelectedBusiness } = businessSlice.actions;
export default businessSlice.reducer;
