import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";

import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/shared/logIn";
import SignUpPage from "./pages/shared/signUp";
// import UserProfile from "./pages/prospect/CompleteProfile";
import BusinessDashboard from "./pages/business/BusinessDashboard";
import UserDashboard from "./pages/prospect/UserDashboard";
import CareerPage from "./pages/career/CareerPage";
import CareerDetailPage from "./pages/career/CareerDetailPage";
import Application from "./pages/application/ApplicationPage";
import { useDispatch, useSelector } from "react-redux";
import { verifyUser } from "./redux/slices/authSlice";
import JobPage from "./pages/jobs/JobPage";
import AdminSignupPage from "./pages/admin/AdminSignUp";
import AdminLoginPage from "./pages/admin/AdminLogin";
import AdminDashboard from "./pages/admin/AdminDashboard";
import BusinessSignUpPage from "./pages/shared/SignUpBusiness";
import BookDemo from "./pages/shared/bookDemo";
import TakeOver from "./pages/career/LastCareerStage";
import LoadingComponent from "./components/loadingSpinner";
import RedirectAfterSignup from "./components/redirectAfterSignup"
import ScrollToTop from "./components/scrollToTop";

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {

    window.gtag('config', 'G-QNVYTR07GW', {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return null; 
};


function App() {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, role } = useSelector((state) => state.auth);

  // Persist user authentication
  useEffect(() => {
    dispatch(verifyUser());
  }, [dispatch]);

  
 
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Analytics />
        <Routes>
          {/* Career Page Route */}
          <Route path="/careers" element={<CareerPage />} />
          {/* Career Detail Page Route */}
          <Route
            path="/careers/:career_path_id"
            element={<CareerDetailPage />}
          />
          {/* Job Detail Page Route */}
          <Route
            path="/careers/:career_path_id/jobs/:jobId"
            element={<JobPage />}
          />
          {/* Job Application Route */}
          <Route
            path="/careers/:career_path_id/jobs/:jobId/apply"
            element={
              isAuthenticated && role === "prospect" ? (
                <Application />
              ) : (
                <Navigate to="/signup" />
              )
            }
          />
          {/* User Dashboard Route */}
          <Route
            path="/user/dashboard"
            element={
              isAuthenticated && role === "prospect" ? (
                <UserDashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* Business Owner Dashboard Routes */}
          <Route
            path="/business/dashboard"
            element={
              isAuthenticated && role === "business_owner" ? (
                <BusinessDashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* Admin Dashboard Routes */}
          <Route
            path="/admin/dashboard"
            element={
              isAuthenticated && role === "admin" ? (
                <AdminDashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* <Route
            path="/user/completeProfile"
            element={
              isAuthenticated && role === "prospect" ? (
                <UserProfile />
              ) : (
                <Navigate to="/" />
              )
            }
          /> */}

          {/* Login Routes */}
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                role === "prospect" ? (
                  <Navigate to="/user/dashboard" />
                ) : role === "business_owner" ? (
                  <Navigate to="/business/dashboard" />
                ) : (
                  <Navigate to="/admin/dashboard" />
                )
              ) : (
                <LoginPage />
              )
            }
          />
          {/* Sign Up Routes */}
          <Route
            path="/signup"
            element={
              isAuthenticated ? (
                <RedirectAfterSignup role={role} />
              ) : (
                <SignUpPage />
              )
            }
          />

          {/* Business Owner Sign up */}
          <Route
            path="/connect_businesses/guruem2024/signup"
            element={
              isAuthenticated ? (
                role === "prospect" ? (
                  <Navigate to="/careers" />
                ) : role === "business_owner" ? (
                  <Navigate to="/business/dashboard" />
                ) : (
                  <Navigate to="/admin/dashboard" />
                )
              ) : (
                <BusinessSignUpPage />
              )
            }
          />
          {/* Book Demo */}
          <Route
            path="/connect_businesses"
            element={
              isAuthenticated ? (
                role === "prospect" ? (
                  <Navigate to="/careers" />
                ) : role === "business_owner" ? (
                  <Navigate to="/business/dashboard" />
                ) : (
                  <Navigate to="/admin/dashboard" />
                )
              ) : (
                <BookDemo />
              )
            }
          />

          {/* Prospect Starting Business */}
          <Route path="/start/your/dream" element={<TakeOver />} />

          {/* Landing Page as the base route */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                role === "prospect" ? (
                  <Navigate to="/user/dashboard" />
                ) : role === "business_owner" ? (
                  <Navigate to="/business/dashboard" />
                ) : (
                  <Navigate to="/admin/dashboard" />
                )
              ) : (
                <LandingPage />
              )
            }
          />
          {/* Admin Sign Up Route */}
          <Route
            path="/admin/guruem2024/signup"
            element={
              isAuthenticated && role === "admin" ? (
                <Navigate to="/admin/dashboard" />
              ) : (
                <AdminSignupPage />
              )
            }
          />
          {/* Admin Login Route */}
          <Route
            path="/admin/guruem2024/login"
            element={
              isAuthenticated && role === "admin" ? (
                <Navigate to="/admin/dashboard" />
              ) : (
                <AdminLoginPage />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
