import * as React from 'react';
import { Box, Drawer, Button, Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function JobDrawer() {
  const [open, setOpen] = React.useState(false);
  
  // Get the selected job opening and its business details from Redux
  const selectedOpening = useSelector((state) => state.job.selectedOpening);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{ width: { xs: '300px', sm: '400px' }, p: 2 }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      {!selectedOpening ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <Box>
         
          {/* Job Title */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 6 }}>
            {selectedOpening.title}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Business Name */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Business: {selectedOpening.business_name}
          </Typography>

          {/* Address */}
          <Typography variant="body2" sx={{ mb: 2 }}>
           {selectedOpening.address}
          </Typography>
          
          <Divider sx={{ my: 2 }} />

          {/* Description */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Description
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedOpening.business_description}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Business Hours */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Hours
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedOpening.business_hour}
          </Typography>
          
          <Divider sx={{ my: 2 }} />

          {/* Years of Operation */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Years of Operation
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
           {selectedOpening.years_of_operation} years
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Perks */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Perks
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {selectedOpening.perks || 'No perks listed'}
          </Typography>

          <Divider sx={{ my: 2 }} />

          
        </Box>
      )}
    </Box>
  );

  return (
    <Box textAlign={'left'}>
      <Button onClick={toggleDrawer(true)} variant="contained" color="primary">
        View Details
      </Button>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </Box>
  );
}
