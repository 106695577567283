import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import JobOpeningCard from "./JobOpeningCard";


export default function JobOpeningList() {
  const { openings } = useSelector((state) => state.job);

  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      // bgcolor: red[500],

    }}
  >
      {openings.map((opening) => (
        <JobOpeningCard opening={opening} key={opening.id}/>
      ))}
    </Container>
  );
}
