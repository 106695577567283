import React, { useEffect, useState } from "react";

import AuthNavigationbar from "../../components/AuthNavigationBar";
import CareerFilter from "../../components/Careers/Career_Page/CareerFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
} from "../../redux/slices/userSlice";
import {
  getCareerPaths,
  setSelectedCareerPath,
} from "../../redux/slices/careerSlice";
import CareerPathCard from "../../components/Careers/Career_Page/CareerPathCard";
import Footer from "../../components/Landing/Footer";
import { Box, Container, Typography } from "@mui/material";



const CareerPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getCareerPaths());
  }, [dispatch]);

  const { paths, selectedPath } = useSelector((state) => state.career);
  const { profile, loading } = useSelector((state) => state.user);

  const [filteredCareers, setFilteredCareers] = useState({
    location: "Ventura, CA",
    career_path_id: "",
  });

  useEffect(() => {
    if (!loading && profile && Object.keys(profile).length > 0) {
      setFilteredCareers({
        location: profile.location,
        career_path_id: profile.career_path_id,
      });
    }
  }, [loading, profile]);

  useEffect(() => {
    if (filteredCareers.career_path_id && paths.length > 0) {
      dispatch(setSelectedCareerPath(filteredCareers.career_path_id));
    }
  }, [filteredCareers.career_path_id, dispatch, paths]);

  useEffect(() => {
    if (filteredCareers.location) {
      localStorage.setItem("userLocation", filteredCareers.location);
    }
  }, [filteredCareers.location, dispatch]);


  return (
    <Box sx={{bgcolor:'background.main'}}>
      <AuthNavigationbar />
      <Box
        sx={{
          position: "relative",
          top: { xs: '40px', sm:'70px'},
          height: 'auto',
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start", 
          alignItems: "space-between", 
          // bgcolor: "background.main",
          pt: 4,
        }}
      > 
    
        <CareerFilter
          onFilterChange={setFilteredCareers}
          filteredCareers={filteredCareers}
          paths={paths}
        />
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "400px",
            my:2,
          }}
        >
          {selectedPath && selectedPath.career_path_name ? (
            <CareerPathCard key={filteredCareers.career_path_id} />
          ) : (
            <Typography variant="body1" color="textSecondary">
              Please select a career path from the filter above.
            </Typography>
          )}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default CareerPage;
