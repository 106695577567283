import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ApplicationCard from "./ApplicationCard";

export default function ApplicationList() {
  const { applications } = useSelector((state) => state.application);
  const sortedApplications = [...applications].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      // bgcolor: red[500],

    }}
  >
      {sortedApplications.map((application) => (
        <ApplicationCard application={application} key={application.application_id}/>
      ))}
    </Container>
  );
}
