import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/slices/authSlice";
import { clearPaths } from "../redux/slices/careerSlice";
import { clearOpenings } from "../redux/slices/jobSlice";
import { clearProfile } from "../redux/slices/userSlice";
import { clearApplication } from "../redux/slices/applicationSlice";
import { clearOwnerProfile } from "../redux/slices/businessSlice";
import { Button } from "@mui/material";

const settings = ["Dashboard", "Logout"];

function AuthNavigationbar() {
  const { isAuthenticated, role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null); // Close the menu first

    // Use switch to navigate based on the setting
    switch (setting) {
      case "Dashboard":
        switch (role) {
          case "prospect":
            navigate("/user/dashboard");
            break;
          case "business_owner":
            navigate("/business/dashboard");
            break;
          case "admin":
            navigate("/admin/dashboard");
            break;
          default:
            break;
        }
        break;
      case "Logout":
        dispatch(logoutUser());
        dispatch(clearPaths());
        dispatch(clearOpenings());
        dispatch(clearProfile());
        dispatch(clearApplication());
        dispatch(clearOwnerProfile());
        navigate('/')
        break;
      default:
        break;
    }
  };

  const handleIconClick = () => {
    if (role === "business_owner") {
      navigate("/business/dashboard");
    } else if (role === "admin") {
      navigate("/admin/dashboard");
    } else {
      navigate("/careers");
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "white",
        minHeight: { xs: "50px", md: "60px" },
        boxShadow: 0,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo */}
          <CloudCircleIcon
            fontSize="large"
            sx={{
              display: { xs: "flex", md: "flex" },
              color: "primary.main",
              cursor: "pointer",
            }}
            onClick={handleIconClick}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            // href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              alignItems: 'center',
              fontFamily:
                "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive", // Custom font family
              fontSize: "32px",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "primary.main",
              textDecoration: "none",
              height: "80px",
              cursor: "pointer",
            }}
            onClick={handleIconClick}
          >
            Cloudens
          </Typography>
          <Box sx={{ flexGrow: 1 }} />{" "}
          {/* Empty box to push user menu to the right */}
          {!isAuthenticated ? (
            <Button
              variant="contained"
              onClick={() => navigate("/signup")}
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                boxShadow: "0 0 8px 3px rgba(0, 123, 255, 0.3)", // Softer glow
                "&:hover": {
                  backgroundColor: "primary.dark",
                  boxShadow: "0 0 12px 6px rgba(0, 123, 255, 0.4)", // Softer hover glow
                },
                animation: "glow 2s infinite alternate",
                "@keyframes glow": {
                  "0%": {
                    boxShadow: "0 0 8px 3px rgba(0, 123, 255, 0.3)", // Initial softer glow
                  },
                  "100%": {
                    boxShadow: "0 0 12px 6px rgba(0, 123, 255, 0.4)", // Softer end glow
                  },
                },
              }}
            >
              Join Us 😎
            </Button>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircleIcon
                    fontSize="large"
                    sx={{ color: "primary.main" }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AuthNavigationbar;
