import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@mui/material";
import { addJobQuestions } from "../../../redux/slices/jobSlice";

// List of predefined application questions
const applicationQuestions = [
  "Why do you want to work for our company, and how does this role align with your career goals?",
  "What specific skills and experiences make you a strong fit for this position?",
  "Can you share an example of a challenge you faced at work and how you overcame it?",
  "How do you prioritize tasks when faced with tight deadlines or multiple responsibilities?",
  "Tell us about a project or accomplishment that you are particularly proud of and why.",
  "How do you handle feedback and criticism, and can you give an example of how you’ve used it to improve your performance?",
  "Describe a time when you had to collaborate with a diverse team. How did you ensure effective communication and understanding?"
];


export default function ApplicationQuestions({
  jobOpeningId,
  onClose,
  questions,
}) {
  const [selectedQuestions, setSelectedQuestions] = useState(new Set());
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedQuestions(new Set(questions));
  }, [questions]);
  

  // Handle question selection toggle
  const handleToggle = (question) => {
    setSelectedQuestions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(question)) {
        newSet.delete(question);
      } else {
        newSet.add(question);
      }
      return newSet;
    });
  };

  // Handle form submission
  const handleSubmit = () => {
    // Convert the Set back to an array for submission
    const formData = {
      job_opening_id: jobOpeningId, // Assuming you're passing the jobOpeningId as a prop
      questions: Array.from(selectedQuestions),
    };

    // Dispatch the action to create the job application
    dispatch(addJobQuestions(formData));
    onClose();
  };
  return (
    <Box
      sx={{
        mt: 4,
        bgcolor: "background.paper",
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Select Three Application Questions for Job Opening
      </Typography>
      <FormGroup>
        {applicationQuestions.map((question, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedQuestions.has(question)}
                onChange={() => handleToggle(question)}
              />
            }
            label={question}
          />
        ))}
      </FormGroup>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={selectedQuestions.size !== 3}
        >
          Submit Questions
        </Button>
      </Box>
    </Box>
  );
}
