import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { loginAdmin } from "../../redux/slices/authSlice";

const AdminLoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector(
    (state) => state.auth
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the loginUser action with the form data
   dispatch(loginAdmin(formData));


  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container
    className="login-page"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      gap: 3,
    }}
  >
    <WavingHandIcon sx={{ fontSize: "2rem", color: "primary.main" }} />
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3 }}>
    Administration Login
    </Typography>

    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        maxWidth: 400,
      }}
    >
      {/* Email */}
      <TextField
        label="Email"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        fullWidth
      />

      {/* Password */}
      <TextField
        label="Password"
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        required
        fullWidth
      />

      {/* Error handling */}
      {error && (
        <Typography color="error" variant="body2">
          {error.message || ''}
        </Typography>
      )}

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        sx={{ py: 1.5 }}
      >
        {loading ? 'Logging in...' : 'Login'}
      </Button>
    </Box>

    {/* Bottom text with link to sign-up */}
    <Typography
        variant="body2"
        sx={{ mt: 2, cursor: "pointer" }} 
        color="primary"
        onClick={() => {
          navigate("/admin/guruem2024/signup"); 
        }}
      >
        Don't have an account? Sign-up
      </Typography>
  </Container>
  );
};

export default AdminLoginPage;
