import React, { useState } from "react";
import { Box, Typography, Button, Container } from "@mui/material";

export default function Solution() {
  const [selectedContent, setSelectedContent] = useState("");

  const content = {
    "": ".....",
    "Job Seekers": `are tired of the endless job search, rejections, and stiff competition. For those dreaming of starting their own business someday, today’s job market can feel discouraging. Instead of getting lost in the crowd, consider small businesses where your skills are valued, demand is high, and opportunities are real. Small businesses offer a chance to grow, learn hands-on, and move closer to your entrepreneurial goals.`,
    "Small Businesses":
      "are facing tough challenges in finding and keeping talent, with 57% struggling to maintain a stable workforce. Unlike big corporations, which attract employees through career mobility and development programs, small businesses lack the resources and infrastructure to compete. This makes hiring and retention frustrating, time-consuming, and a barrier to growth.",
  };

  return (
    <Box
      id="Solution"
      sx={{ backgroundColor: "background.main", py: 8, textAlign: "center" }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h4"
          textAlign="left"
          marginBottom={"20px"}
          gutterBottom
        >
          In 2024, you...
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Button Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              width: "100%",
              mb: 4,
            }}
          >
            <Button
              variant={
                selectedContent === "Job Seekers" ? "contained" : "outlined"
              }
              sx={{
                mr: 2,
                borderColor:
                  selectedContent === "Job Seekers"
                    ? "transparent"
                    : "primary.main",
                boxShadow:
                  selectedContent === "Job Seekers"
                    ? "none"
                    : "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor:
                    selectedContent === "Job Seekers"
                      ? "primary.main"
                      : "rgba(63, 81, 181, 0.1)",
                  transform:
                    selectedContent === "Job Seekers" ? "none" : "scale(1.05)",
                  boxShadow:
                    selectedContent === "Job Seekers"
                      ? "none"
                      : "0px 4px 10px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => setSelectedContent("Job Seekers")}
            >
              Job Seekers
            </Button>

            <Button
              variant={
                selectedContent === "Small Businesses"
                  ? "contained"
                  : "outlined"
              }
              sx={{
                borderColor:
                  selectedContent === "Small Businesses"
                    ? "transparent"
                    : "primary.main",
                boxShadow:
                  selectedContent === "Small Businesses"
                    ? "none"
                    : "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor:
                    selectedContent === "Small Businesses"
                      ? "primary.main"
                      : "rgba(63, 81, 181, 0.1)",
                  transform:
                    selectedContent === "Small Businesses"
                      ? "none"
                      : "scale(1.05)",
                  boxShadow:
                    selectedContent === "Small Businesses"
                      ? "none"
                      : "0px 4px 10px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => setSelectedContent("Small Businesses")}
            >
              Small Businesses
            </Button>
          </Box>

          <Box
            sx={{
              background: "linear-gradient(135deg, #2962ff 0%, #1e3c72 100%)", // Gradient for the text
              WebkitBackgroundClip: "text",
              backgroundClip: "text", // Ensure it works across all browsers
              color: "transparent", // Make the text transparent to show the gradient
              p: 3,
              borderRadius: 3, // Increase border radius for a softer look
              width: "100%",
              minHeight: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)", // Enhanced shadow for depth
              position: "relative",
              overflow: "hidden", // For decorative effects like glow or pseudo-elements
            }}
          >
            <Typography variant="body1" textAlign={"left"}>
              {content[selectedContent]}
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="h4"
          textAlign="left"
          sx={{ margin: "40px auto" }}
          gutterBottom
        >
          So <strong>Cloudens</strong> is here...
        </Typography>
        <Box
          sx={{
            background: `linear-gradient(135deg, #2962ff 0%, #1e3c72 100%)`, // Gradient effect using theme's primary color and a deep blue
            color: "white",
            p: 3,
            borderRadius: 3, // Increase border radius for a softer look
            width: "100%",
            minHeight: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)", // Enhanced shadow for depth
            position: "relative",
            overflow: "hidden", // For decorative effects like glow or pseudo-elements
            textAlign: "left",
          }}
        >
          {selectedContent.length === 0 ? (
            "..."
          ) : (
            <Box>
              <Typography variant="body1" gutterBottom>
                to a career-focused job board designed specifically for small
                businesses. Beyond job listings, we curate personalized career
                roadmaps aligned with prospects’ goals, guiding them through
                each stage of their career with opportunities from a network of
                related businesses. This helps keep employees engaged and
                motivated, knowing they are actively building their careers with
                real growth opportunities.
                {/* to help small businesses <strong>hire more effectively and keep their
                teams strong at all times!</strong> We understand that current Applicant
                Tracking Systems (ATS) used by many job boards have left local
                service-based businesses across America frustrated and
                underserved. */}
                {/* to help <strong>job seekers</strong> find jobs that match their career goals, not just
                any job. We give clear steps and plans that show how job
                openings can lead to the careers people want. This helps job
                seekers build skills and grow, so one day, they can start
                their own businesses. */}
              </Typography>
              <Typography>
                {/* We don’t just offer a platform to post jobs; we build a
                community of small businesses centered around <strong style={{ fontStyle:'italic', fontSize: '20px' }}>career path</strong>. In this
                community, job seekers can find real opportunities that align
                with their career goals at any stage, while businesses benefit
                from a more dynamic hiring process. Our unique approach promotes 
                <strong style={{ fontStyle:'italic', fontSize: '20px' }}> external career mobility</strong> by enabling job opportunities through
                <strong style={{ fontStyle:'italic', fontSize: '20px' }}> an internal referral system</strong>, where hiring starts and dreams come
                true for both employers and employees. */}
                {/* For <strong>small businesses</strong>, we connect them with people who are eager
                to work and have a good record. This gives them a steady and
                reliable way to build strong teams. By being part of our
                network, small businesses can offer the same growth and career
                paths that big companies do, helping both their employees and
                their business grow. */}
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
