import { Container } from "@mui/material";
import { useSelector } from "react-redux"
import ProspectInfo from "./ProspectInfo";




export default function Prospects(){
    const {users} = useSelector((state)=> state.user);
    return(
        <Container
        sx={{
          display: "flex",
          flexDirection: "column",
    
        }}
      >
          {users.map((user) => (
            <ProspectInfo user={user} key={user.id}/>
          ))}
        </Container>
      );
}