  import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
  import axios from "axios";
import { toast } from "react-toastify";

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:4001/api"; // For local dev

  //Register a new user
  export const signUpUser = createAsyncThunk(
    "auth/signUpUser", async (
      { first_name, last_name, email, password, role },
      { rejectWithValue }
    ) => {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/auth/register`,
          {
            first_name,
            last_name,
            email,
            password,
            role,
          },
          {
            withCredentials: true, // Send token in cookie
          }
        );
        return response.data.user; // Expecting role in response data
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );



  //Logs in a user
  export const loginUser = createAsyncThunk(
    "auth/loginUser",
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/auth/login`,
          { email, password },
          {
            withCredentials: true,
          }
        );

        return response.data.user;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

//Logs out a user
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      console.log("enters");
      const response = await axios.get(`${apiBaseUrl}/auth/logout`, {
        withCredentials: true, // Ensure cookies are sent
      });
      console.log("leaves");

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error("Logout failed");
      }

      return {}; // Successful logout
    } catch (error) {
      // If there is an error, pass the error message using rejectWithValue
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

  //Verifies a user when refresh
  export const verifyUser = createAsyncThunk(
    "auth/verifyUser",
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch(`${apiBaseUrl}/auth/verify`, {
          method: "GET",
          credentials: "include", // Ensures the cookie is sent with the request
        });

        if (!response.ok) {
          throw new Error("Not authenticated");
        }

        const data = await response.json(); 
        return data; 
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );



  //Register a new Admin
  export const signUpAdmin = createAsyncThunk(
    "auth/signUpAdmin", async (
      { first_name, last_name, email, password, admin_code, role },
      { rejectWithValue }
    ) => {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/admin/register`,
          {
            first_name,
            last_name,
            email,
            password,
            admin_code,
            role,
          },
        );
        return response.data.user; 
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

//Logs in an Admin
export const loginAdmin = createAsyncThunk(
  "auth/loginAdmin",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/login`,
        { email, password },
        {
          withCredentials: true,
        }
      );
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

  const authSlice = createSlice({
    name: "auth",
    initialState: {
      isAuthenticated: null,
      role: null, // 'businessOwner' or 'prospect'
      loading: true, //false enforces the app to navigate to the dashboard
      error: null,
      first_name: null,
    },
    reducers: {
      clearState: (state) => {
        state.isAuthenticated = false;
        state.role = null;
        state.loading = false;
        state.error = null;
      },

    },
    extraReducers: (builder) => {
      // Handle sign-up
      builder
        .addCase(signUpUser.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(signUpUser.fulfilled, (state, { payload }) => {
          state.isAuthenticated = true;
          state.role = payload.role;
          state.loading = false;
          toast.success( 'Thank you for joining us!', {
            position: "top-center",
            icon: '👋'
          });
        })
        .addCase(signUpUser.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
         
          toast.error( state.error.errors[0].message, {
            position: "top-center",
            icon: '❌'
          });
        });

      // Handle login
      builder
        .addCase(loginUser.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(loginUser.fulfilled, (state, { payload }) => {
          state.isAuthenticated = true;
          state.role = payload.role;
          state.loading = false;
        })
        .addCase(loginUser.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          toast.error( state.error.errors[0].message, {
            position: "top-center",
            icon: '❌'
          });
        });

      // Handle logout
      builder
        .addCase(logoutUser.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(logoutUser.fulfilled, (state) => {
          state.isAuthenticated = false;
          state.role = null;
          state.loading = false;
        })
        .addCase(logoutUser.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });

      // Handle Refresh
      builder
        .addCase(verifyUser.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(verifyUser.fulfilled, (state, { payload }) => {
          state.isAuthenticated = true; 
          state.role = payload.role;
          state.loading = false;
        })

        .addCase(verifyUser.rejected, (state, { payload }) => {
          state.loading = false;
          state.isAuthenticated = false; 
          state.role = null; 
          state.error = payload;
        });
         // Handle ADMIN sign-up
     builder
         .addCase(signUpAdmin.pending, (state) => {
           state.loading = true;
           state.error = null;
         })
         .addCase(signUpAdmin.fulfilled, (state, { payload }) => {
           state.isAuthenticated = true;
           state.role = payload.role;
           state.loading = false;
           state.first_name = payload.first_name;
           toast.success( 'New Admin has been added!', {
            position: "top-center",
            icon: '👋'
          });
         })
         .addCase(signUpAdmin.rejected, (state, { payload }) => {
           state.loading = false;
           state.error = payload;
           toast.error( state.error.errors[0].message, {
            position: "top-center",
            icon: '❌'
          });
         });
      // Handle Admin log in
      builder
        .addCase(loginAdmin.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(loginAdmin.fulfilled, (state, { payload }) => {
          state.isAuthenticated = true;
          state.role = payload.role;
          state.loading = false;
          state.first_name = payload.first_name;
        })
        .addCase(loginAdmin.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          toast.error( state.error.errors[0].message, {
            position: "top-center",
            icon: '❌'
          });
        });
 
    },
  });

  export const { clearState } = authSlice.actions;
  export default authSlice.reducer;
