import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getIndustries } from "../../../redux/slices/careerSlice"; // Adjust the import based on your folder structure

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  maxHeight: "80%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function BusinessForm({ onsubmit, onClose, existingBusiness }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  const { industries } = useSelector((state) => state.career);

  const [formData, setFormData] = useState({
    name: existingBusiness?.name || "",
    address: existingBusiness?.address || "",
    years_of_operation: existingBusiness?.years_of_operation || '',
    industry_id: existingBusiness?.industry_id || "",
    perks: existingBusiness?.perks || "",
    description: existingBusiness?.description || "",
    business_hour: existingBusiness?.business_hour || "",
    customer_reviews: existingBusiness?.customer_reviews?.join("; ") || "",
    employee_testimonials:
      existingBusiness?.employee_testimonials?.join("; ") || "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const processedFormData = {
      ...formData,
      customer_reviews: formData.customer_reviews
        .split(";")
        .map((review) => review.trim()),
      employee_testimonials: formData.employee_testimonials
        .split(";")
        .map((testimonial) => testimonial.trim()),
    };
    console.log(processedFormData);
    onsubmit(processedFormData);
    onClose();

    if (!existingBusiness) {
      setFormData({
        name: "",
        address: "",
        years_of_operation: '',
        industry_id: "",
        perks: "",
        description: "",
        business_hour: "",
        customer_reviews: "",
        employee_testimonials: "",
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={style}>
        <Typography
          variant="h6"
          gutterBottom
          textAlign="center"
          color="primary"
        >
          {existingBusiness ? "Update Business" : "Create Business"}
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Business Name */}
          <TextField
            fullWidth
            label="Business Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Address */}
          <TextField
            fullWidth
            label="Business Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Business Hours */}
          <TextField
            fullWidth
            label="Business Hours"
            name="business_hour"
            value={formData.business_hour}
            onChange={handleChange}
            required
            multiline
            rows={2}
            sx={{ mb: 2 }}
          />

          {/* Years of Operation */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="years-of-operation-label">
              How long has your business been established?
            </InputLabel>
            <Select
              labelId="years-of-operation-label"
              name="years_of_operation"
              value={formData.years_of_operation}
              onChange={handleChange}
            >
              <MenuItem value="1-5">1-5 years</MenuItem>
              <MenuItem value="6-10">6-10 years</MenuItem>
              <MenuItem value="11-15">11-15 years</MenuItem>
              <MenuItem value="16-20">16-20 years</MenuItem>
              <MenuItem value="20+">20+ years</MenuItem>
            </Select>
            {/* <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
              Applicants are more likely to apply when they see the stability
              and experience of your business!
            </Typography> */}
          </FormControl>

          {/* Industry Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="industry-label">
              Which industry best describes your business?
            </InputLabel>
            <Select
              labelId="industry-label"
              name="industry_id"
              value={formData.industry_id}
              onChange={handleChange}
              multiline
              rows={2}
              required
              label="Industry"
            >
              {industries.map((industry) => (
                <MenuItem key={industry.id} value={industry.id} sx={{ m: 2 }}>
                  {industry.name} - {industry.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Perks */}
          <TextField
            fullWidth
            label="What kind of perks do you offer your team?"
            name="perks"
            value={formData.perks}
            onChange={handleChange}
   
            sx={{ mb: 2 }}
          />

          {/* Description */}
          <TextField
            fullWidth
            label="Describe Your Business"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            multiline
            rows={2}
            sx={{ mb: 2 }}
          />

          {/* Customer Reviews */}
          <TextField
            fullWidth
            label='Provide any significant customer reviews (separate them with ;)'
            name="customer_reviews"
            value={formData.customer_reviews}
            onChange={handleChange}
            helperText={'(Highlighting positive customer feedback builds trust and credibility, making applicants more excited to work for a business with a proven track record of success and satisfaction.)'}
            multiline
            rows={2}
            sx={{ mb: 2 }}
          />

          {/* Employee Testimonials */}
          <TextField
            fullWidth
            label='Ask your employees to write down their testimonies about working here! (separate them with ;)'
            name="employee_testimonials"
            value={formData.employee_testimonials}
            onChange={handleChange}
            helperText={"(Employee testimonials provide authentic insights and can inspire job seekers to join a supportive and positive work environment.)"}
            multiline
            rows={2}
            sx={{ mb: 2 }}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, py: 1 }}
          >
            {existingBusiness ? "Update Business" : "Create Business"}
          </Button>
        </form>
      </Box>
    </Container>
  );
}
