import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getJobOpeningsForCareerPath } from "../../redux/slices/jobSlice";

import AboutDrawer from "../../components/Careers/Career_Detail_Page/CareerDrawer";
import AuthNavigationbar from "../../components/AuthNavigationBar";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import {
  Avatar,
  Box,
  ListItem,
  Typography,
} from "@mui/material";
import CareerStageStepper from "../../components/Careers/Career_Detail_Page/CareerStages";
import Footer from "../../components/Landing/Footer";
import {
  getCareerPaths,
  setSelectedCareerPath,
} from "../../redux/slices/careerSlice";


const CareerDetailPage = () => {
  const dispatch = useDispatch();
  const { career_path_id } = useParams();
  const { loading, selectedPath, paths } = useSelector((state) => state.career);
  const { openings, loading: jobLoading } = useSelector((state) => state.job);
  const userLocation = localStorage.getItem("userLocation");

  useEffect(() => {
    if (paths.length === 0) {
      dispatch(getCareerPaths()).then(() => {
        dispatch(setSelectedCareerPath(career_path_id));
      });
    }
    dispatch(getJobOpeningsForCareerPath(career_path_id));
  }, [paths.length, dispatch, career_path_id]);


  // if (loading || !selectedPath) {
  //   return <div>Loading career details...</div>;
  // }

  // if (jobLoading || !openings) {
  //   return <div>Loading job openings...</div>;
  // }

  return (
    <Fragment>
      <AuthNavigationbar />
      <Box
        sx={{
          position: "relative",
          top: "70px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
          backgroundColor: "background.main",
          boxShadow: 3,
          p: { xs: 2, md: 4 },
          gap: 2,
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            padding: 2,
            borderRadius: 2,
            width: { xs: "100%", sm: "400px" },
            boxShadow: 2,
          }}
        >
          <Box>
            <Typography
              variant="h6"
              component="h1"
              fontWeight="bold"
              gutterBottom
              sx={{
                paddingTop: 2,
                // // fontFamily: "'Pacifico', cursive", // Use a special, cursive font to make it unique
                // // background: "linear-gradient(90deg, #2962ff, #6dd5ed)", // Gradient background for the text
                // WebkitBackgroundClip: "text", // Clip the background to the text for Webkit browsers
                // backgroundClip: "text", // Clip the background to the text
                // color: "transparent", // Make the text transparent to show the gradient
                // textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Add a subtle shadow for depth
                // letterSpacing: "0.1em", // Slightly increase letter spacing for a modern look
                // "&:hover": {
                //   textShadow: "4px 4px 8px rgba(0, 0, 0, 0.5)", // Enhance shadow on hover
                //   transform: "scale(1.05)", // Slight zoom-in effect on hover
                //   transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
                // },
                color: 'primary.main'
              }}
            >
              {selectedPath.career_path_name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Explore the path to success with this career in{" "}
              <strong>{userLocation}</strong>
            </Typography>

            {/* Career Accordion Section */}
            <AboutDrawer />
          </Box>

          <Box>
            {/* Success Stories */}
            <Typography
              variant="h6"
              textAlign="left"
              sx={{ mt: 4, fontWeight: "bold", mb: 1 }}
            >
              <EmojiEventsIcon
                sx={{
                  color: "secondary.main",
                }}
              /> Inspiring Journeys
            </Typography>

            {selectedPath.success_stories_business_owners?.length > 0 ? (
              selectedPath.success_stories_business_owners.map(
                (story, index) => (
                  <Box
                  key={index}
                    sx={{
                      borderRadius: 2,
                      outline: 1,
                      p: 1,
                      mb: 2,
                      bgcolor: "white",
                      color: "primary.main",
                      boxShadow: 2,
                    }}
                  >
                    <ListItem>
                      {/* <Avatar sx={{ mr: 2, bgcolor: "primary.main" }}></Avatar> */}
                      <Typography variant="body2">😎 {story}</Typography>
                    </ListItem>
                  </Box>
                )
              )
            ) : (
              <Typography variant="body2">
                No success stories available.
              </Typography>
            )}
          </Box>
        </Box>

        <CareerStageStepper />
      </Box>
      <Footer />
    </Fragment>
  );
};

export default CareerDetailPage;
