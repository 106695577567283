import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUpUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Box, Button, TextField, Typography } from "@mui/material";

const BusinessSignUpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state for form input
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "business_owner",
  });

  // Access loading and error states from Redux
  const { loading, error } = useSelector((state) => state.auth);

  // Handle form input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.gtag("event", "book_meeting", {
      event_category: "conversion",
      event_label: "Business Owner Booking",
      value: 1,
    });
    dispatch(signUpUser(formData));
  };

  return (
    <Box
      className="signup-page"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: 3,
        p: 2,
      }}
    >
      <StorefrontIcon sx={{ fontSize: "2rem", color: "primary.main" }} />
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Business Sign Up
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 3 }}>
        Thank you for signing up with us!
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          maxWidth: 400,
        }}
      >
        {/* First Name */}
        <TextField
          label="First Name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Last Name */}
        <TextField
          label="Last Name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Email */}
        <TextField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Password */}
        <TextField
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          helperText="Password must be at least 8 characters long and include one letter, one number, and one special character."
          fullWidth
        />

        {/* Error handling */}
        {error && (
          <Typography color="error" variant="body2">
            {error.message || ""}
          </Typography>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ py: 1.5 }} // Larger button
        >
          {loading ? "Signing up..." : "Sign Up"}
        </Button>
      </Box>

      <Typography
        variant="body2"
        sx={{ mt: 2, cursor: "pointer" }}
        color="primary"
        onClick={() => {
          navigate("/login");
        }}
      >
        Already have an account? Log in
      </Typography>
    </Box>
  );
};
export default BusinessSignUpPage;
