import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  Container,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import jobOpeningImage from "../../../images/restaurantImage.jpg";
import LegalStatement from "./LegalStatement";

const JobDetails = () => {
  const navigate = useNavigate();
  const { selectedOpening } = useSelector((state) => state.job);
  const { isAuthenticated } = useSelector((state) => state.auth);
  if (!selectedOpening || Object.keys(selectedOpening).length === 0) {
    return <div>Loading job details...</div>;
  }

  const {
    business_name,
    career_path_id,
    id,
    title,
    pay_range,
    work_hours,
    job_description,
    full_time,
    core_responsibilities = [],
    work_conditions,
    training_and_education,
    skills_required = [],
    bonuses,
  } = selectedOpening;

  return (
    <Grid container spacing={2} sx={{ml:{sm:0}}}>
      {/* Image Section */}
      <Grid item xs={12} sm={5}>
        <Box
          component="img"
          src={jobOpeningImage}
          alt="Career Path"
          sx={{
            width: "100%",
            height: "auto",
            borderRadius: 2,
          }}
        />
      </Grid>

      {/* Job Info Section */}
      <Grid item xs={12} sm={7}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            textAlign: "left",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              outline: 2,
              p: 2,
              borderRadius: 2,
              boxShadow: 4,
              color: "primary.main",
            }}
          >
            <Typography variant="h6" component="h1" sx={{ mb: 2 }}>
              {title}
            </Typography>
            <Typography variant="body1">{job_description}</Typography>
          </Box>

          <Divider sx={{ my: 2, width: "100%" }} />

          {/* Job Details */}
          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Pay Range</Typography>
              <Typography variant="body2">{pay_range}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Work Availability</Typography>
              <Typography variant="body2">{work_hours}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2">Full Time</Typography>
              <Typography variant="body2">
                {full_time ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, width: "100%" }} />

          {/* Responsibilities */}
          {core_responsibilities.length > 0 && (
            <Box sx={{ my: 2, width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Responsibilities
              </Typography>
              <Grid container spacing={2}>
                {core_responsibilities.map((resp, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography variant="subtitle2">- {resp}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <Divider sx={{ my: 2, width: "100%" }} />

          {/* Skills Required */}
          {skills_required.length > 0 && (
            <Box sx={{ my: 2, width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Skills Required
              </Typography>
              <Grid container spacing={2}>
                {skills_required.map((skill, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography variant="subtitle2">- {skill}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <Divider sx={{ my: 2, width: "100%" }} />

          {/* Work Conditions */}
          {work_conditions && (
            <Box sx={{ my: 2, width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Work Conditions
              </Typography>
              <Typography variant="body2">{work_conditions}</Typography>
            </Box>
          )}

          <Divider sx={{ my: 2, width: "100%" }} />

          {/* Training and Education */}
          {training_and_education && (
            <Box sx={{ my: 2, width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Training and Education
              </Typography>
              <Typography variant="body2">{training_and_education}</Typography>
            </Box>
          )}

          <Divider sx={{ my: 2, width: "100%" }} />

          {/* Benefits */}
          {bonuses && (
            <Box sx={{ my: 2, width: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Bonuses
              </Typography>
              <Typography variant="body2">{bonuses}</Typography>
            </Box>
          )}

          <LegalStatement businessName={business_name} />

          {/* Apply Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              localStorage.setItem("url", window.location.pathname);
              if (isAuthenticated) {
                navigate(`/careers/${career_path_id}/jobs/${id}/apply`);
              } else {
                navigate("/signUp");
              }
            }}
            sx={{
              textAlign: "center",
              mt: 4,
              width: { xs: "100%", sm: "50%" },
              alignSelf: "center",
            }}
          >
            Apply Now
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default JobDetails;
