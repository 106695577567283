import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import BusinessCard from "./BusinessCard";

export default function BusinessList() {
  const { businesses } = useSelector((state) => state.business);

  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      // bgcolor: red[500],
    }}
  >
      {businesses.map((business) => (
        <BusinessCard business={business} key={business.id}/>
      ))}
    </Container>
  );
}
