import React from "react";
import { Box, Typography, Avatar, Divider } from "@mui/material";
import { useSelector } from "react-redux";

export default function ProfileDisplay() {
  const profile = useSelector((state) => state.user.profile);
  const { paths } = useSelector((state) => state.career);
  const path = paths.find(
    (path) => path.career_path_id === profile.career_path_id
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center", // Centering for better balance
        backgroundColor: "white",
        borderRadius: 2,
        p: 3, // Increase padding for better spacing
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds subtle shadow
        width: "100%",
        maxWidth: "500px", // Limit the width for better focus
        mx: "auto", // Horizontally center the box
      }}
    >
      <Avatar
        alt={`${profile.first_name} ${profile.last_name}`}
        sx={{ width: 45, height: 45, mb: 2 }}
      />

      {/* Name and Career Path Section */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          textAlign: "center", // Center the name for better emphasis
          mb: 1,
        }}
      >
        {profile.first_name} {profile.last_name}
      </Typography>

      <Typography variant="subtitle1" sx={{ textAlign: "center", mb: 1 }}>
        {path ? path.career_path_name : "No career path found"}
      </Typography>

      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        {profile.location
          ? `Location: ${profile.location}`
          : "Update Your Location"}
      </Typography>

      <Divider sx={{ width: "100%", mb: 2 }} />

      {/* Profile Details Section */}
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          Profile Information
        </Typography>

        <Box textAlign={"left"}>
          <Typography variant="subtitle1" color="primary">
            Email:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {profile.email || "No email provided"}
          </Typography>

          <Typography variant="subtitle1" color="primary">
            Skills and Certifications:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {profile.skills &&
            profile.skills.length > 0 &&
            profile.skills[0] !== ""
              ? profile.skills.join(", ")
              : "No skills provided"}
          </Typography>

          <Typography variant="subtitle1" color="primary">
            Driven and Committed:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {profile.willingness_to_work || "No information provided"}
          </Typography>

          <Typography variant="subtitle1" color="primary">
            Committed to Growth:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {profile.willingness_to_learn || "No information provided"}
          </Typography>

          <Typography variant="subtitle1" color="primary">
            Your Most Recent Experience:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {profile.previous_experience || "No previous experience provided"}
          </Typography>

          <Typography variant="subtitle1" color="primary">
            Your Reference:
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {profile.reference || "No reference provided"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
