import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";

import businessImage from "../../../images/businessImage.jpg";

import PostAddIcon from "@mui/icons-material/PostAdd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import {
  deleteBusiness,
  updateBusiness,
} from "../../../redux/slices/businessSlice";

import Modal from "@mui/material/Modal";

import JobOpeningForm from "../../Jobs/BusinessDashboardControl/JobOpeningForm";
import { createJobOpening } from "../../../redux/slices/jobSlice";
import BusinessDrawer from "./BusinessDrawer";
import BusinessForm from "./businessForm";

export default function BusinessCard({ business }) {
  const dispatch = useDispatch();

  const {
    id,
    name,
    address,
    // years_of_operation,
    // description,
    // perks,
    // business_hour,
  } = business;

  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = useState(1);
  const handleOpen = (num) => {
    setOpen(true);
    setFormType(num);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removeBusiness = () => {
    dispatch(deleteBusiness(id));
  };

  const handleClick = (formData) => {
    if (formType === 1) {
      const editFormData = { id: id, ...formData };
      dispatch(updateBusiness(editFormData));
    } else {
      dispatch(createJobOpening(formData));
    }
  };
  return (
    <Card
      sx={{
        width: "100%",
        bgcolor: "white",
        height: "100%",
        // minHeight: "200px",
        borderRadius: 2,
        boxShadow: 3,
        marginBottom: 2,
      }}
    >
      {/* Image */}
      <Box sx={{ position: "relative", textAlign: "center" }}>
        <img
          src={businessImage}
          alt="Career Path"
          style={{
            width: "100%",
            maxHeight: "50px",
            objectFit: "cover",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        />
        <RemoveCircleIcon
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            color: "white",
            cursor: "pointer",
            "&:hover": {
              color: "red",
            },
          }}
          onClick={() => removeBusiness()}
        />
        <EditIcon
          sx={{
            position: "absolute",
            top: 12,
            left: 12,
            color: "white",
            cursor: "pointer",
            "&:hover": {
              color: "green",
            },
          }}
          onClick={() => handleOpen(1)}
        />
      </Box>

      <CardContent>
        {/* Container for better layout alignment */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            mx: 2,
          }}
        >
          {/* Business Name */}
          <Typography
            variant="body1"
            component="div"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            {name}
          </Typography>

          {/* Business Address */}
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {address}
          </Typography>

          {/* Business Drawer Component */}
          <BusinessDrawer business={business} />

          {/* Post Job Button */}
          <Tooltip title="Post a job opening">
            <Button
              variant="outlined"
              size="small"
              sx={{
                mt: 2,
                width: "100%",
              }}
              onClick={() => handleOpen(2)}
              startIcon={<PostAddIcon />}
            >
              Post Job
            </Button>
          </Tooltip>
        </Box>
      </CardContent>

      <Modal open={open} onClose={handleClose}>
        {formType === 1 ? (
          <BusinessForm
            onsubmit={handleClick}
            onClose={handleClose}
            existingBusiness={business}
          />
        ) : (
          <JobOpeningForm
            onsubmit={handleClick}
            onClose={handleClose}
            selectedBusiness={id}
          />
        )}
      </Modal>
    </Card>
    // </Box>
  );
}
