import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:4001/api"; // For local dev

// Gets all career paths -Admin
export const getCareerPaths = createAsyncThunk(
  "career/getPaths",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/career_paths`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets a specific career path
export const getCareerPathById = createAsyncThunk(
  "career/getSpecificPath",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/career_paths/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Gets all industries
export const getIndustries = createAsyncThunk(
  "career/getIndustries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/industries`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a new career path
export const createCareerPath = createAsyncThunk(
  "career/createPath",
  async (newPath, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/career_paths`,
        newPath,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing career path
export const updateCareerPath = createAsyncThunk(
  "career/updatePath",
  async (updatedPath, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/career_paths/${updatedPath.id}`,
        updatedPath,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete a career path
export const deleteCareerPath = createAsyncThunk(
  "career/deletePath",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`${apiBaseUrl}/career_paths/${id}`, {
        withCredentials: true,
      });
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a new career stage for a career path
export const createStage = createAsyncThunk(
  "career/createStage",
  async (newStage, { rejectWithValue }) => {
    try {

      const response = await axios.post(
        `${apiBaseUrl}/career_paths/career_stage`,
        newStage,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Career slice
const careerSlice = createSlice({
  name: "career",
  initialState: {
    paths: [], // List of career paths
    loading: false,
    error: null,
    industries: [],
    selectedPath: {},
  },
  reducers: {
    clearPaths: (state) => {
      state.paths = [];
      state.selectedPath = {};
    },
    setSelectedCareerPath(state, action) {
      state.selectedPath = state.paths.find(
        (path) => path.career_path_id === action.payload
      );
    },
  },
  extraReducers: (builder) => {
    // Handle getCareerPaths
    builder
      .addCase(getCareerPaths.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCareerPaths.fulfilled, (state, { payload }) => {
        state.paths = payload; // Store the fetched career paths
        state.loading = false;
      })
      .addCase(getCareerPaths.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    builder
      .addCase(getIndustries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIndustries.fulfilled, (state, { payload }) => {
        state.industries = payload; // Store the fetched career paths
        state.loading = false;
      })
      .addCase(getIndustries.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle createCareerPath
    builder
      .addCase(createCareerPath.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCareerPath.fulfilled, (state, { payload }) => {
        state.paths = [...state.paths, payload];
        state.loading = false;
      })
      .addCase(createCareerPath.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle updateCareerPath
    builder
      .addCase(updateCareerPath.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCareerPath.fulfilled, (state, { payload }) => {
        const index = state.paths.findIndex(
          (path) => path.career_path_id === payload.career_path_id
        );
        if (index !== -1) {
          state.paths[index] = payload; // Update the existing path with new data
        }
        state.loading = false;
      })
      .addCase(updateCareerPath.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle deleteCareerPath
    builder
      .addCase(deleteCareerPath.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCareerPath.fulfilled, (state, { meta }) => {
        state.paths = state.paths.filter(
          (path) => path.career_path_id !== meta.arg
        ); // Remove the deleted path
        state.loading = false;
      })
      .addCase(deleteCareerPath.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle createStage
    builder
      .addCase(createStage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStage.fulfilled, (state, { payload }) => {
        const index = state.paths.findIndex(
          (path) => path.career_path_id === payload.career_path_id
        );
        if (index !== -1) {
          state.paths[index].career_stages = [...state.paths[index].career_stages, payload]
        }
        state.loading = false;
      })
      .addCase(createStage.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearPaths, setSelectedCareerPath } = careerSlice.actions;
export default careerSlice.reducer;
