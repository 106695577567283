import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "80%",
  maxHeight: "80%",
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function StageForm({ career_path_id, onSubmit, onClose }) {
  const [formData, setFormData] = useState({
    career_path_id: career_path_id,
    stage_number: 1,
    title: "",
    stage_description: "",
    skills_required: "",
    pay_range: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    const processedFormData = {
        ...formData,
        skills_required: formData.skills_required.split(",").map((skill) => skill.trim()),
    }

    onSubmit(processedFormData);
    onClose(); 

    // Reset the form
    setFormData({
      stage_number: "",
      title: "",
      stage_description: "",
      skills_required: [],
      pay_range: "",
    });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={style}>
        <Typography variant="h6" gutterBottom textAlign="center" color="primary">
          Create Career Stage
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Stage Number */}
          <TextField
            fullWidth
            label="Stage Number (1-5)"
            name="stage_number"
            value={formData.stage_number}
            onChange={handleChange}
            required
            type="number"
            sx={{ mb: 2 }}
          />

          {/* Stage Title */}
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Stage Description */}
          <TextField
            fullWidth
            label="Stage Description"
            name="stage_description"
            value={formData.stage_description}
            onChange={handleChange}
            required
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />

          {/* Skills Required */}
       
           <TextField
            fullWidth
            label="Skills Required"
            name="skills_required"
            value={formData.skills_required}
            onChange={handleChange}
            required
            multiline
            rows={2}
            sx={{ mb: 2 }}
          />

          {/* Pay Range */}
          <TextField
            fullWidth
            label="Pay Range"
            name="pay_range"
            value={formData.pay_range}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 4 }}
          >
            Create Career Stage
          </Button>
        </form>
      </Box>
    </Container>
  );
}
