import React from "react";
import { Box, Typography, Button, Container, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import heroImage from "../../images/heroImage.JPG";
import ContactForm from "./ContactForm";
import Solution from "./Solution";

const Hero = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/careers");
  };

  return (
    <>
      <Box
        sx={{
          height: { xs: "88vh", md: "88vh" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            p: { xs: 3, md: 5 },
            textAlign: { xs: "center", md: "right" },
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            color="primary.main"
            gutterBottom
            sx={{
              // fontFamily:
              //   "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive",
              fontWeight: 'bold',
              fontSize: {
                xs: "1.5rem", // Small screen
                sm: "2rem", // Medium screen
                md: "3rem", // Large screen
                // lg: "5rem", // Extra large screen
              },
              // lineHeight: {
              //   xs: "2.5rem",
              //   sm: "3.5rem",
              //   md: "4.5rem",
              //   lg: "5.5rem",
              // },
            }}
          >
            CLOUDENS LANDED
          </Typography>

          {/* <Typography
            variant="h6"
            component="p"
            sx={{
              fontSize: {
                xs: "1rem", // Small screen
                sm: "1rem", // Medium screen
                md: "1.2rem", // Large screen
              },
            }}
            gutterBottom
          >
        
          </Typography> */}
          <Box
            sx={{
              display: "inline-flex",
              bgcolor: "primary.main",
              borderRadius: 2,
              p: 2,
              // boxShadow: "2px 4px 8px rgba(41, 98, 255, 0.5)", // Soft shadow
              textAlign: { xs: "center", sm: "right" },
              ml: { xs: "0px", sm: "40px", md: "80px", lg: "100px" },
            }}
          >
            <Typography
              variant="h6"
              component="p"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: {
                  xs: "1rem", // Small screen
                  sm: "1rem", // Medium screen
                  md: "1.2rem", // Large screen
                },
                letterSpacing: "0.05em", // Slightly increase letter spacing
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", // Subtle text shadow for depth
                position: "relative",
              }}
              gutterBottom
            >
              Job Board Guiding You from Start to Success in Small Business
              Careers Across <span style={{ fontStyle: "italic", fontSize: '1.2rem' }}>Ventura County</span>
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGetStarted}
            sx={{
              mt: 4,
              px: 4,
              py: 2,
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              background: "linear-gradient(90deg, #3f51b5, #1e88e5)",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                background: "linear-gradient(90deg, #1e88e5, #3f51b5)",
                transform: "scale(1.05)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            Get Started
          </Button>
        </Box>
        <Box
          component="img"
          src={heroImage}
          alt="Career Path"
          sx={{
            display: { xs: "none", md: "block" },
            width: "50%",
            height: "100%",
            objectFit: "cover",
            boxShadow: 10,
            maskImage:
              "linear-gradient(to right, transparent, black 30%), linear-gradient(to top, transparent, black 10%)",
            WebkitMaskImage:
              "linear-gradient(to right, transparent, black 30%), linear-gradient(to top, transparent, black 10%)",
            maskComposite: "intersect",
            WebkitMaskComposite: "source-in",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "80px",
          bgcolor: "#1e3c72", // Dark blue background
          color: "white", // White text color
          overflow: "hidden", // Hide overflowing content for ticker effect
          position: "relative", // Ensure correct positioning for animation
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow effect
          "&::before": {
            content: '""',
            position: "absolute",
            top: 4, // Align to the top
            left: 0,
            right: 0,
            borderTop: "2px dotted white", // Dotted line styling for the top
          },
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 4, // Align to the bottom
            left: 0,
            right: 0,
            borderBottom: "2px dotted white", // Dotted line styling for the bottom
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            animation: "scroll 50s linear infinite",
            "@keyframes scroll": {
              "0%": { transform: "translateX(30%)" },
              "100%": { transform: "translateX(-100%)" },
            },
            whiteSpace: "nowrap", // Prevent text wrapping for ticker effect
          }}
        >
          <Typography sx={{ paddingRight: "100px" }}>
            Ventura County’s extensive support for entrepreneurs, including
            mentorship, funding, and industry-specific training, makes it a top
            choice for small businesses to thrive and expand​
            <span style={{ fontStyle: "italic", color: "#a5d6a7" }}>
              {" "}
              (Source: Business Forward Ventura County){" "}
            </span>
          </Typography>
          <Typography sx={{ paddingRight: "100px" }}>
            Many home service entrepreneurs have scaled their businesses into
            multimillion-dollar operations.
            <span style={{ fontStyle: "italic", color: "#a5d6a7" }}>
              {" "}
              (Source: Ryno Services Podcast){" "}
            </span>
          </Typography>
          <Typography sx={{ paddingRight: "100px" }}>
            Small businesses create nearly two-thirds of new private-sector jobs
            in the U.S.
            <span style={{ fontStyle: "italic", color: "#a5d6a7" }}>
              {" "}
              (Source: U.S. Small Business Administration){" "}
            </span>
          </Typography>
          <Typography sx={{ paddingRight: "100px" }}>
            Ivy League graduates are increasingly buying small businesses for
            growth opportunities.
            <span style={{ fontStyle: "italic", color: "#a5d6a7" }}>
              {" "}
              (Source: Harvard Business Review){" "}
            </span>
          </Typography>
          <Typography sx={{ paddingRight: "100px" }}>
            Private equity firms are acquiring small businesses due to stable
            demand and profitability.
            <span style={{ fontStyle: "italic", color: "#a5d6a7" }}>
              {" "}
              (Source: SharpSheets Financial Insights){" "}
            </span>
          </Typography>
        </Box>
      </Box>

      {/* Problem and Solution Statement */}
      <Solution />

      {/* About Us Section */}
      <Box
        id="About Us"
        sx={{ backgroundColor: "white", py: 8, textAlign: "center" }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              my: 2,
            }}
          >
            <Typography sx={{ mr: 1, fontSize: "24px" }}>😊</Typography>
            <Typography variant="h6">Hey! JD Here</Typography>
          </Box>

          <Typography variant="body1" textAlign={"left"} mb={"20px"}>
            From 2020 to 2023, I ran a small business in Ventura and faced many
            challenges, but one issue stood out: hiring and keeping a reliable
            team was the most exhausting and frustrating task. I tried platforms
            like Indeed and other online tools, but none were tailored to the
            unique needs of small businesses. That’s why I’m here today—to build
            the solution I wish I had: a platform designed specifically for the
            needs of small businesses.
          </Typography>
          <Typography variant="body1" textAlign={"left"}>
            Our mission is simple:{" "}
            <strong> “Every job is filled, and everyone is hired.”</strong>{" "}
            We’re working to create an ecosystem where both job seekers and
            employers are satisfied. We believe that when both sides are happy,
            we’ve found the true solution to the hiring challenges small
            businesses face today.
          </Typography>
        </Container>
      </Box>

      {/* Contact Us Section */}
      <ContactForm />
    </>
  );
};

export default Hero;
