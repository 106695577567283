import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { signUpAdmin } from "../../redux/slices/authSlice";




const AdminSignupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state for form input
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    admin_code: "",
    role: "admin",
  });


  const { loading } = useSelector(
    (state) => state.auth
  );

  // Handle form input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(signUpAdmin(formData));
 
  };
 
  return (
    <Container
      className="signup-page"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: 3,
      }}
    >
      <AssignmentIndIcon sx={{ mt:4, fontSize: "2rem", color: "primary.main" }} />
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", mb: 3 }}
      >
        Administration Sign Up
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          maxWidth: 400,
        }}
      >
        {/* First Name */}
        <TextField
          label="First Name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Last Name */}
        <TextField
          label="Last Name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Email */}
        <TextField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Password */}
        <TextField
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          helperText="Password must be at least 8 characters long and include one letter, one number, and one special character."
          fullWidth
        />

        {/* admin-code */}
        <TextField
          label="Admin Access Code"
          type="password"
          name="admin_code"
          value={formData.admin_code}
          onChange={handleChange}
          required
          fullWidth
        />

        {/* Role Dropdown */}
        {/* <FormControl fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            label="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <MenuItem value="admin">Company Admin</MenuItem>
          </Select>
        </FormControl> */}

        {/* Error handling */}
        {/* {error && (
          <Typography color="error" variant="body2">
            {error.message || ""}
          </Typography>
        )} */}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ py: 1.5 }} // Larger button
        >
          {loading ? "Signing up..." : "Sign Up"}
        </Button>
      </Box>

      <Typography
        variant="body2"
        sx={{ mt:2, cursor: "pointer" }}
        color="primary"
        onClick={() => {
          navigate("/admin/guruem2024/login");
        }}
      >
        Already have an account? Log in
      </Typography>
    </Container>
  );
};
export default AdminSignupPage;
