import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

export default function BookDemo() {
    const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    business_email: "",
    phone_number: "",
    location: "",
    company_name: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const data = new FormData(form);

    fetch("https://formspree.io/f/mzzbpeob", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFormData({
            first_name: "",
            last_name: "",
            business_email: "",
            phone_number: "",
            location: "",
            company_name: "",
          }); // Reset the form fields
          toast.success(
            "Thank you for reaching out! The team will contact you shortly.",
            {
              position: "top-center",
              icon: "✅",
            }
          ); navigate('/careers');
          
        } else {
          console.log("Form submission error", response);
          toast.error("Error submitting the form. Please try again.", {
            position: "top-center",
            icon: "❌",
          });
        }
      })
      .catch((error) => {
        console.error("Form submission error", error);
        toast.error("Error submitting the form. Please try again.", {
          position: "top-center",
          icon: "❌",
        });
      });
  };

  return (
    <Container
      sx={{
        display: "flex",
        position:'relative',
        top: 10,
        flexDirection: { xs: "column", md: "row" },
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        // bgcolor:'background.main',
        // borderRadius: 2,
      }}
    >
      {/* Info Section */}
      <Box sx={{ textAlign: "center", maxWidth: 400 }}>
        <Typography variant="h4" gutterBottom fontWeight="bold">
          Empowering Small Businesses, Together
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Hiring is a long-term journey, not a one-time task. Together, we
          create lasting opportunities for growth, making it easier for your
          business to build and maintain a strong, successful team.
        </Typography>
      </Box>

      {/* Form Section */}
      <Box sx={{ maxWidth: 400, width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          Be part of our community!
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* First Name */}
          <TextField
            label="First Name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            size="small"
            required
            fullWidth
          />

          {/* Last Name */}
          <TextField
            label="Last Name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
              size="small"
            fullWidth
          />

          {/* Business Email */}
          <TextField
            label="Business Email"
            type="email"
            name="business_email"
            value={formData.business_email}
            onChange={handleChange}
            required
              size="small"
            fullWidth
          />

          {/* Phone Number */}
          <TextField
            label="Phone Number"
            type="tel"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            required
              size="small"
            fullWidth
          />

          {/* Location */}
          <TextField
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
              size="small"
            fullWidth
          />

          {/* Company Name */}
          <TextField
            label="Company Name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            required
              size="small"
            fullWidth
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ py: 1.5 }} // Larger button
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
