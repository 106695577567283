import { Container } from "@mui/material";
import React from "react";
import StageCard from "./StageCard";

export default function StagesList({path}) {


  const career_stages = path.career_stages;

  return (
    <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      // bgcolor: red[500],

    }}
  >
      {career_stages.map((career_stage) => (
        <StageCard career_stage={career_stage} key={career_stages.stage_number}/>
      ))}
    </Container>
  );
}
