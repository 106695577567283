import React from "react";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Avatar,
  Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCareerPath } from "../../../redux/slices/careerSlice";

const CareerPageFilter = ({ filteredCareers, onFilterChange, paths }) => {
  const targetLocations = ["Ventura, CA",];

  const dispatch = useDispatch();
  const selectedPath = useSelector((state) => state.career.selectedPath);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    const updatedFilters = { ...filteredCareers, [name]: value };
    onFilterChange(updatedFilters);

    if (name === "career_path_id") {
      dispatch(setSelectedCareerPath(value));
    }
  };

  return (
    <Container
      sx={{
        width: "auto",
        minWidth: "300px",
        maxWidth: "fit-content",
        height: "auto",
        p: 2,
        bgcolor: "white",
        borderRadius: 4,
        boxShadow: "0px 10px 30px rgba(41, 98, 255, 0.4)", // Enhanced shadow with a hint of primary color
        position: "relative",
        overflow: "hidden", // To contain pseudo-elements inside the container
        // "&:hover": {
        //   transform: "scale(1.02)", // Slight scaling effect on hover
        //   transition: "transform 0.3s ease-in-out",
        // },
      }}
    >
      <Box>
        {selectedPath ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              alignItems: "center",
              gap: { xs: 1, sm: 1 },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Avatar sx={{ bgcolor: "primary.main", width: 32, height: 32 }}>
              C
            </Avatar>
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive",
                display: { xs: "flex", sm: "none" },
              }}
            >
              Choose Your Path...
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive",
                display: { xs: "none", sm: "flex" },
              }}
            >
              I want to be a{" "}
            </Typography>

            {/* Career Path Select */}
            <FormControl
              sx={{
                minWidth: { xs: "100%", sm: "200px" },
                mx: 1,
                mb: { xs: 1, sm: 0 },
              }}
              size="small"
            >
              <InputLabel>Career Path</InputLabel>
              <Select
                name="career_path_id"
                value={filteredCareers.career_path_id}
                onChange={handleFilterChange}
                label="Career Path"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 2,
                  bgcolor: "white",
                  textAlign: "center",
                  fontSize: "0.875rem",
                  outline: 2,
                  height: "40px",
                  width: "auto",
                }}
              >
                {paths.map((path) => (
                  <MenuItem
                    key={path.career_path_id}
                    value={path.career_path_id}
                    // sx={{ fontWeight: "bold", fontSize: "0.875rem",  }}
                  >
                    {path.career_path_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography
              variant="subtitle1"
              sx={{
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive",
                display: { xs: "none", sm: "flex" },
              }}
            >
              in the
            </Typography>

            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "100%", sm: "200px" },
                p: 1.2,
                fontWeight: "bold",
                borderRadius: 2,
                border: '2px solid',
                bgcolor: "background.main",
                mb: { xs: 1, sm: 0 },
                fontSize: "0.875rem",
                minHeight: "42px",
              }}
            >
              {selectedPath.industry_name || "Select a Career Path"}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                fontFamily:
                  "'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', cursive",
                display: { xs: "none", sm: "flex" },
              }}
            >
              in
            </Typography>

            {/* Location Select */}
            <FormControl
              sx={{
                width: { xs: "100%", sm: "150px" },
                mx: 1,
                mb: { xs: 2, sm: 0 },
              }}
              size="small"
            >
              <InputLabel>Location</InputLabel>
              <Select
                name="location"
                value={filteredCareers.location}
                onChange={handleFilterChange}
                label="Location"
                sx={{
                  fontWeight: "bold",
                  borderRadius: 2,
                  bgcolor: "white",
                  fontSize: "0.875rem",
                  textAlign: "center",
                  outline: 2,
                  height: "40px",
                }}
              >
                {targetLocations.map((location, index) => (
                  <MenuItem key={index} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          "Please Wait"
        )}
      </Box>
    </Container>
  );
};

export default CareerPageFilter;
