import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:4001/api"; // For local dev

//Gets All Prospects -Admin
export const getAllProspects = createAsyncThunk(
  "user/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/admin/users`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Gets user profile
export const getUserProfile = createAsyncThunk(
  "user/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/account/user`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Updates user profile
export const updateUserProfile = createAsyncThunk(
  "user/updateProfile",
  async (profileData, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/account/user`, profileData, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Deletes user profile
export const deleteUserProfile = createAsyncThunk(
  "user/deleteProfile",
  async (_, { rejectWithValue }) => {
    try {
      await axios.delete(`${apiBaseUrl}/account/user`, {
        withCredentials: true,
      });
      return {};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Gets job applications
export const getUserApplications = createAsyncThunk(
  "user/getApplications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/account/user/applications`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Initial state
const initialState = {
  profile: {},
  loading: false,
  error: null,
  userApplications: [],
  users: [],
};

// User slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearProfile: (state) => {
      state.profile = {};
      state.userApplications = [];
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    // Handle getAllProspects
    builder
      .addCase(getAllProspects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllProspects.fulfilled, (state, { payload }) => {
        state.users = payload;
        state.loading = false;
      })
      .addCase(getAllProspects.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle getUserProfile
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.loading = false;
      })
      .addCase(getUserProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle updateUserProfile
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.loading = false;
      })
      .addCase(updateUserProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle deleteUserProfile
    builder
      .addCase(deleteUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUserProfile.fulfilled, (state) => {
        state.profile = {};
        state.loading = false;
        toast.error("Your User Profile Has Been Deleted", {
          position: "top-center",
          icon: '👋',
        });
      })
      .addCase(deleteUserProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // Handle getUserApplications
    builder
      .addCase(getUserApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserApplications.fulfilled, (state, { payload }) => {
        state.userApplications = payload;
        state.loading = false;
      })
      .addCase(getUserApplications.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { clearProfile } = userSlice.actions;
export default userSlice.reducer;
