import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../redux/slices/userSlice";

export default function ProfileEditForm({ onClose }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const { paths } = useSelector((state) => state.career);
  const targetLocations = ["Ventura, CA", "Los Angeles, CA", "Irvine, CA"];

  // Initialize form data with existing profile info
  const [formData, setFormData] = useState({
    first_name: profile.first_name || "",
    last_name: profile.last_name || "",
    email: profile.email || "",
    career_path_id: profile.career_path_id || "",
    location: profile.location || "",
    skills: profile.skills ? profile.skills.join(", ") : "",
    willingness_to_work: profile.willingness_to_work || "",
    willingness_to_learn: profile.willingness_to_learn || "",
    previous_experience: profile.previous_experience || "",
    reference: profile.reference || "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert skills to array format before submitting
    const updatedFormData = {
      ...formData,
      skills: formData.skills.split(",").map((skill) => skill.trim()),
    };

    dispatch(updateUserProfile(updatedFormData));
    onClose();
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // minHeight: "100vh",
        backgroundColor: "white",
        p: 4,
      }}
    >
      {/* <Typography variant="h6" gutterBottom>
        Edit Profile Information
      </Typography> */}

      {/* First Name */}
      <TextField
        fullWidth
        label="First Name"
        name="first_name"
        value={formData.first_name}
        onChange={handleChange}
        required
        size="small"
        sx={{ mb: 2 }}
      />

      {/* Last Name */}
      <TextField
        fullWidth
        label="Last Name"
        name="last_name"
        value={formData.last_name}
        onChange={handleChange}
        required
        size="small"
        sx={{ mb: 2 }}
      />

      {/* Career Path Selection */}
      <FormControl fullWidth size="small" sx={{ mb: 2 }}>
        <InputLabel id="career_label">Choose Your Career</InputLabel>
        <Select
          labelId="career_label"
          name="career_path_id"
          value={formData.career_path_id}
          onChange={handleChange}
          required
          label="Career_Path"
          sx={{
            textAlign: "left",
            borderRadius: 1,
          }}
        >
          {paths.map((path) => (
            <MenuItem key={path.career_path_id} value={path.career_path_id}>
              {path.career_path_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Password
      <TextField
        fullWidth
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        sx={{ mb: 2 }}
      /> */}

      {/* Location */}
      <FormControl
        fullWidth
        sx={{
          mb: 2,
        }}
      >
        <InputLabel id="location-label">Location</InputLabel>
        <Select
          labelId="location-label"
          name="location"
          value={formData.location}
          onChange={handleChange}
          label="Location"
          size="small"
          sx={{
            textAlign: "left",
            borderRadius: 1,
          }}
        >
          {targetLocations.map((location, index) => (
            <MenuItem key={index} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Email */}
      <TextField
        fullWidth
        label="Email"
        name="email"
        value={formData.email}
        disabled
        required
        size="small"
        sx={{ mb: 2 }}
      />

      {/* Skills */}
      <TextField
        fullWidth
        label="Skills and Certifications"
        name="skills"
        value={formData.skills}
        onChange={handleChange}
        placeholder="Enter skills separated by commas"
        sx={{ mb: 2 }}
      />

      {/* Willingness to Work */}
      <TextField
        fullWidth
        label="Driven and Committed"
        name="willingness_to_work"
        value={formData.willingness_to_work}
        onChange={handleChange}
        multiline
        rows={2}
        sx={{ mb: 2 }}
      />

      {/* Willingness to Learn */}
      <TextField
        fullWidth
        label="Committed to Growth"
        name="willingness_to_learn"
        value={formData.willingness_to_learn}
        onChange={handleChange}
        multiline
        rows={2}
        sx={{ mb: 2 }}
      />

      {/* Previous Experience */}
      <TextField
        fullWidth
        label="Most Recent Experience"
        name="previous_experience"
        value={formData.previous_experience}
        onChange={handleChange}
        multiline
        rows={2}
        sx={{ mb: 2 }}
      />

      {/* Reference */}
      <TextField
        fullWidth
        label="Your Reference"
        name="reference"
        value={formData.reference}
        onChange={handleChange}
        multiline
        rows={2}
        sx={{ mb: 2 }}
      />

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Update Profile
      </Button>
    </Box>
  );
}
