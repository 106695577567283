import React, { useState } from "react";
import { Box, Typography, Button, TextField, Container } from "@mui/material";
import { toast } from "react-toastify";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const form = e.target;
    const data = new FormData(form);
    
    fetch("https://formspree.io/f/mzzbpeob", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFormData({ name: "", email: "", message: "" }); 
          toast.success('Your Message Has Been Submitted!', {
            position: "top-center",
            icon: "✅",
          })
        } else {
          console.log("Form submission error", response);
        }
      })
      .catch((error) => console.error("Form submission error", error));
  };

  return (
    <Container id="Contact" sx={{ py: 4, textAlign: "center", }}>
      <Container maxWidth="md" sx={{bgcolor: 'white', p:4, borderRadius: 2, boxShadow: "4px 4px 8px rgba(41, 98, 255, 0.8)"}}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Have any questions or want to get in touch with us? Feel free to reach out!
        </Typography>
        <Typography variant="subtitle2" fontWeight={'bold'} gutterBottom>
          guruemjobboard@gmail.com
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 4,
          }}
        >
          <TextField
            name="name"
            label="Your Name"
            variant="outlined"
            fullWidth
            sx={{ maxWidth: 400 }}
            required
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            name="email"
            label="Your Email"
            variant="outlined"
            fullWidth
            sx={{ maxWidth: 400 }}
            type="email"
            required
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            name="message"
            label="Your Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            sx={{ maxWidth: 400 }}
            required
            value={formData.message}
            onChange={handleChange}
          />
          <Button variant="contained" color="primary" sx={{ mt: 2 }} size="large" type="submit">
            Send Message
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default ContactForm;
