import { Container } from "@mui/material";
import { useSelector } from "react-redux"
import OwnerInfo from "./OwnerInfo";



export default function Owners(){
    const {businessOwners} = useSelector((state)=> state.business);
    return(
        <Container
        sx={{
          display: "flex",
          flexDirection: "column",
    
        }}
      >
          {businessOwners.map((owner) => (
            <OwnerInfo owner={owner} key={owner.id}/>
          ))}
        </Container>
      );
}