import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { red } from "@mui/material/colors";

export default function ProspectInfo({ user }) {
  const { applications } = useSelector((state) => state.application);
  const { first_name, last_name, created_at } = user;

  // Format the prospect's created_at date
  const formattedDate = created_at
    ? format(new Date(created_at), "MM/dd/yyyy")
    : "Date not available";

  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 1,
        mb: 1,
      }}
    >
      {/* Prospect Information */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            boxShadow: 1,
            borderRadius: 2,
            backgroundColor: "white",
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
            {first_name} {last_name}
          </Typography>
          <Typography variant="caption" color="textSecondary" sx={{ mb: 2 }}>
            Joined on: {formattedDate}
          </Typography>
        </Box>
      </Grid>

      {/* Applications Information */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            maxHeight: "400px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            boxShadow: 1,
            borderRadius: 2,
            backgroundColor: "background.main",
            p: 2,
          }}
        >
          {/* Fixed Title */}
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
          >
            Applications
          </Typography>
          <Box
            sx={{
              flex: 1,
              overflowY: "auto", // Enable vertical scrolling
              width: "100%",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar for Webkit browsers (Chrome, Safari)
              },
              "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer and Edge
              "scrollbar-width": "none", // Hide scrollbar for Firefox
            }}
          >
            {applications
              .filter((app) => app.prospect_id === user.id)
              .map((application) => {
                const formattedAppDate = application.created_at
                  ? format(new Date(application.created_at), "MM/dd/yyyy")
                  : "Date not available";

                return (
                  <Box
                    key={application.id}
                    sx={{
                      mb: 2,
                      p: 2,
                      backgroundColor:
                        application.application_status === "declined"
                          ? red[100]
                          : "#ffffff",
                      borderRadius: 1,
                      boxShadow: 1,
                      width: "100%",
                      textAlign: 'left'
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "#333" }}
                    >
                      {application.job_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          application.application_status === "declined"
                            ? "red"
                            : "primary.main",
                      }}
                    >
                      Status: {application.application_status}
                    </Typography>
                    <Typography variant="caption" sx={{ color: "#666" }}>
                      Applied on: {formattedAppDate}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#666" }}>
                      Answer 1: {application.answer_1}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#666" }}>
                      Answer 2: {application.answer_2}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#666" }}>
                      Answer 3: {application.answer_3}
                    </Typography>
                  </Box>
                );
              })}

            {applications.filter((app) => app.prospect_id === user.id)
              .length === 0 && (
              <Typography variant="body2" color="textSecondary">
                No applications found for this prospect.
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
