import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

function RedirectAfterSignup({ role }) {
  const navigate = useNavigate();
  const previousUrl = localStorage.getItem("url");

  React.useEffect(() => {
    if (previousUrl) {
      // console.log("url: ",previousUrl)
      navigate(`${previousUrl}/apply`);
      localStorage.removeItem("url");
    }
  }, [navigate, previousUrl]);

  // Fallback navigation based on role
  if (role === "prospect") {
    return <Navigate to="/user/dashboard" />;
  } else if (role === "business_owner") {
    return <Navigate to="/business/dashboard" />;
  } else {
    return <Navigate to="/admin/dashboard" />;
  }
}

export default RedirectAfterSignup;
