import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Container,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getIndustries } from "../../../redux/slices/careerSlice"; 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "80%",
  maxHeight: "80%",
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  boxShadow: 3,
  borderRadius: 2,
};

export default function CareerPathForm({ onsubmit, onClose, path }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  const { industries } = useSelector((state) => state.career);



  const [formData, setFormData] = useState({
    name: path?.career_path_name || "",
    overview: path?.career_path_overview || "",
    industry_id: path?.industry_id || "",
    career_path_outlook: path?.career_path_outlook || "",
    alignment_with_career_goals: path?.alignment_with_career_goals || "",
    success_stories_business_owners: path?.success_stories_business_owners?.join("; ") || "",
  });


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const processedFormData = {
      ...formData,
      success_stories_business_owners: formData.success_stories_business_owners
        .split(";")
        .map((story) => story.trim()),
    };

    onsubmit(processedFormData); 
    onClose();
  };

  return (
    <Container maxWidth="sm">
      <Box sx={style}>
        <Typography variant="h6" gutterBottom textAlign="center" color="primary">
          {path ? "Edit Career Path" : "Create Career Path"}
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Career Path Name */}
          <TextField
            fullWidth
            label="Career Path Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          {/* Overview */}
          <TextField
            fullWidth
            label="Overview"
            name="overview"
            value={formData.overview}
            onChange={handleChange}
            required
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />

          {/* Industry Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="industry-label">Industry</InputLabel>
            <Select
              labelId="industry-label"
              name="industry_id"
              value={formData.industry_id}
              onChange={handleChange}
              required
              label="Industry"
            >
              {industries.map((industry) => (
                <MenuItem key={industry.id} value={industry.id}>
                  {industry.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Career Path Outlook */}
          <TextField
            fullWidth
            label="Career Path Outlook"
            name="career_path_outlook"
            value={formData.career_path_outlook}
            onChange={handleChange}
            required
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          {/* Alignment with Career Goals */}
          <TextField
            fullWidth
            label="Alignment with Career Goals"
            name="alignment_with_career_goals"
            value={formData.alignment_with_career_goals}
            onChange={handleChange}
            required
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          {/* Success Stories */}
          <TextField
            fullWidth
            label="Success Stories from Business Owners (Separated by ;)"
            name="success_stories_business_owners"
            value={formData.success_stories_business_owners}
            onChange={handleChange}
            required
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 4 }}
          >
            {path ? "Update Career Path" : "Create Career Path"}
          </Button>
        </form>
      </Box>
    </Container>
  );
}
