import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Box,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateApplication } from "../../../redux/slices/applicationSlice";
import { format } from "date-fns";

export default function ApplicationCard({ application }) {
  const {
    created_at,
    application_id,
    application_status,
    answer_1,
    answer_2,
    answer_3,
    first_name,
    last_name,
    email,
    skills,
    reference,
    willingness_to_work,
    willingness_to_learn,
    previous_experience,
    job_title,
    business_name,
  } = application;

  const dispatch = useDispatch();
  const [status, setStatus] = useState(application_status);

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    dispatch(
      updateApplication({ id: application_id, application_status: newStatus })
    );
  };

  return (
    <Card
      sx={{
        backgroundColor:
          status === "declined"
            ? "grey.300"
            : status === "accepted"
            ? "secondary.main"
            : "white", // Default to white
        mb: 2,
        p: 2,
        boxShadow: 3,
        borderRadius: 2,
        textAlign: "left",
      }}
    >
      <CardContent>
        <Typography variant="caption" color="textSecondary">
          Applied on: {`${format(new Date(created_at), "MMMM dd, yyyy")}`}
        </Typography>
        <Typography variant="h6">{job_title}</Typography>
        <Typography variant="caption" gutterBottom>
          {business_name}
        </Typography>
        

        <Divider />

        <Typography variant="subtitle2" mt={2}>
          <strong>Prospect:</strong> {first_name} {last_name}
        </Typography>
        <Typography variant="subtitle2">
          <strong>Email:</strong> {email}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Questionnaires:
          </Typography>
          <Typography variant="body2">1. {answer_1}</Typography>
          <Typography variant="body2">2. {answer_2}</Typography>
          <Typography variant="body2">3. {answer_3}</Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Applicant Information:
          </Typography>
          <Typography variant="body2">
            <strong>Skills:</strong> {skills?.join(", ")}
          </Typography>
          <Typography variant="body2">
            <strong>Reference:</strong> {reference}
          </Typography>
          <Typography variant="body2">
            <strong>Dedication:</strong> {willingness_to_work}
          </Typography>
          <Typography variant="body2">
            <strong>Growth:</strong> {willingness_to_learn}
          </Typography>
          <Typography variant="body2">
            <strong>Experience:</strong> {previous_experience}
          </Typography>
        </Box>

        
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Typography variant="subtitle2">Application Status</Typography>
          <Select value={status} size="small" onChange={handleStatusChange}>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="declined">Declined</MenuItem>
            <MenuItem value="hired">Hired</MenuItem>
          </Select>
        </FormControl>
        {status === "accepted" ? (
          <Typography
            sx={{
              fontWeight: "bold", // Make the text bold
              fontSize: "1rem", // Slightly increase the font size
              color: "primary.main", // Use the primary color to make it stand out
              backgroundColor: "#f0f4ff", // Light blue background to highlight the text
              padding: "8px 16px", // Add padding around the text for emphasis
              borderRadius: "8px", // Rounded corners for a modern look
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Soft shadow for depth
              textAlign: "center", // Center align the text
              letterSpacing: "0.05em", // Slightly increase letter spacing for better readability
              mt:2
            }}
          >
            Send an email to proceed with in-person interview!
          </Typography>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
}
