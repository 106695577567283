import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../../redux/slices/userSlice";
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const CompleteProfileForm = ({ profile }) => {
  const dispatch = useDispatch();

  // State for user profile form
  const [formData, setFormData] = useState({
    reference: profile?.reference || "",
    skills: profile?.skills?.join(", ") || "",
    willingness_to_work: profile?.willingness_to_work || "",
    willingness_to_learn: profile?.willingness_to_learn || "",
    previous_experience: profile?.previous_experience || "",
  });

    useEffect(()=>{
      setFormData(
       { reference: profile?.reference || "",
        skills: profile?.skills?.join(", ") || "",
        willingness_to_work: profile?.willingness_to_work || "",
        willingness_to_learn: profile?.willingness_to_learn || "",
        previous_experience: profile?.previous_experience || "",}
      )
    }, [profile])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const processedFormData = {
      ...formData,
      skills: formData.skills.split(",").map((skill) => skill.trim()),
    };
    dispatch(updateUserProfile(processedFormData));
    toast.success('Your Profile Has Been Updated!');
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "space-even",
        mt: 2,
        bgcolor: "white",
        p: 2,
        borderRadius: 2,
        // boxShadow: 3,
        maxWidth: "600px",
        mx: "auto",
        textAlign: "left",
      }}
    >
      <Typography sx={{ mb: 4, fontWeight: "bold", color: "primary.main" }}>
        Application: (1/2)
      </Typography>
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {profile.willingness_to_learn
            ? "Update Your Profile"
            : "Complete Your Profile"}
        </Typography>


        {/* Reference */}
        <Typography sx={{mb:2}}>Who can refer you to this job?</Typography>
        <TextField
          fullWidth
          label="Reference (e.g., previous employer, mentor, or professional contact)"
          name="reference"
          value={formData.reference}
          onChange={handleChange}
          helperText="Provide the name and contact details of someone who can vouch for your work ethic or skills."
          sx={{ mb: 2 }}
        />
        {/* Skills and Certifications */}
        <Typography sx={{mb:2}}>What skills and certifications do you have?</Typography>
        <TextField
          fullWidth
          label="Skills and Certifications (e.g. food handling certification, Microsoft Office proficiency)"
          name="skills"
          value={formData.skills}
          onChange={handleChange}
          required
          helperText="List your skills and certifications relevant to the job you're applying for. Be specific."
          sx={{ mb: 2 }}
        />
        {/* Willingness to Work */}
        <Typography sx={{mb:2}}>
          How would you describe your work motivation and availability?
        </Typography>
        <TextField
          fullWidth
          label="(e.g. highly motivated, open availability, willing to work weekends)"
          name="willingness_to_work"
          value={formData.willingness_to_work}
          onChange={handleChange}
          helperText="Describe your availability and motivation for the job. Indicate if you have a flexible schedule or prefer specific hours."
          sx={{ mb: 2 }}
        />
        {/* Willingness to Learn */}
        <Typography sx={{mb:2}}>
          How open are you to learning new skills or receiving training?
        </Typography>
        <TextField
          fullWidth
          label="(e.g. open to training, eager to develop new skills, willing to take on new challenges)"
          name="willingness_to_learn"
          value={formData.willingness_to_learn}
          onChange={handleChange}
          helperText="Explain your approach to learning and developing new skills. Show your eagerness to grow and adapt."
          sx={{ mb: 2 }}
        />
        {/* Previous Experience */}
        <Typography sx={{mb:2}}>What relevant work experience do you have?</Typography>
        <TextField
          fullWidth
          label="Previous Experience (e.g. job title, years of experience, specific responsibilities)"
          name="previous_experience"
          value={formData.previous_experience}
          onChange={handleChange}
          multiline
          rows={4}
          helperText="Detail your most recent work history, including job titles, key responsibilities, and years of experience. Focus on experiences relevant to the role you're applying for."
          sx={{ mb: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          width="auto"
          sx={{ mt: 2, py: 1 }}
        >
          {profile.willingness_to_work ? "Edit Profile" : "Submit Profile"}
        </Button>
      </form>
    </Box>
  );
};

export default CompleteProfileForm;
