import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import ProspectApplicationCard from "./ProspectApplicationCard"; // Assuming this is your card component


export default function ProspectApplicationsList() {
  const { userApplications } = useSelector((state) => state.user);

  // Filter applications based on status
  const pendingApplications = userApplications.filter(
    (app) => app.application_status === "pending"
  );
  const acceptedApplications = userApplications.filter(
    (app) => app.application_status === "accepted"
  );
  const declinedApplications = userApplications.filter(
    (app) => app.application_status === "declined"
  );
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap:2,
        bgcolor: "white",
        padding: 2,
        borderRadius: 2,
      }}
    >
      {/* Pending Section */}
      <Typography variant="h6">Pending</Typography>
      <Divider />
      <Box
        sx={{
          padding: 2,
          maxHeight: "250px",
          minHeight: '100px',
          overflowY: "auto", // Allows vertical scrolling
          "&::-webkit-scrollbar": {
            width: "0px", // Hides the scrollbar for webkit-based browsers (e.g., Chrome, Safari)
          },
          scrollbarWidth: "none", // Hides the scrollbar for Firefox
        }}
      >
        {pendingApplications.length > 0 &&
          pendingApplications.map((application) => (
            <ProspectApplicationCard application={application} />
          ))}
      </Box>

      {/* Accepted Section */}
      <Typography variant="h6">Accepted</Typography>
      {acceptedApplications.length >0 ? <Typography variant="caption">Check Your Email!</Typography>: <></>}
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: "secondary.main",
          padding: 2,
          maxHeight: "250px",
          minHeight: '0px',
          overflowY: "auto", // Allows vertical scrolling
          "&::-webkit-scrollbar": {
            width: "0px", // Hides the scrollbar for webkit-based browsers (e.g., Chrome, Safari)
          },
          scrollbarWidth: "none", // Hides the scrollbar for Firefox
        }}
      >
        {acceptedApplications.length > 0 &&
          acceptedApplications.map((application) => (
            <ProspectApplicationCard application={application} />
          ))}
      </Box>
      {/* Declined Section */}
      <Typography variant="h6">Declined</Typography>
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: "grey",
          padding: 2,
          maxHeight: "250px",
          minHeight: '0px',
          overflowY: "auto", // Allows vertical scrolling
          "&::-webkit-scrollbar": {
            width: "0px", // Hides the scrollbar for webkit-based browsers (e.g., Chrome, Safari)
          },
          scrollbarWidth: "none", // Hides the scrollbar for Firefox
        }}
      >
        {declinedApplications.length > 0 &&
          declinedApplications.map((application) => (
            <ProspectApplicationCard application={application} />
          ))}
      </Box>
    </Container>
  );
}
