import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid"; // Import Grid for layout
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setSelectedOpening } from "../../../redux/slices/jobSlice";


export default function JobList({ stage }) {
  const {career_path_id} = useParams();
  const userLocation = localStorage.getItem("userLocation");
  const location = userLocation.split(',');
  const { openings } = useSelector((state) => state.job);
  const dispatch = useDispatch();

  const jobOpenings = openings?.filter(
    (opening) => opening.job_stage === stage && opening.active === true && opening.address.includes(location[0])
  );
  

  const navigate = useNavigate();

  const viewJobOpening = (jobOpening) => {
    dispatch(setSelectedOpening(jobOpening.id))
    navigate(`/careers/${career_path_id}/jobs/${jobOpening.id}`);
  };
  return (
    <List
      sx={{
        // width: "100%",
        maxHeight: '400px',
        overflowY: "scroll", // Allow vertical scrolling
        bgcolor: "background.paper",
        "&::-webkit-scrollbar": {
          width: "0px", // Set scrollbar width to 0 to make it invisible
        },
      }}
    >
      { jobOpenings && jobOpenings.length > 0 ?
      
      jobOpenings.map((jobOpening) => (
        <React.Fragment key={jobOpening.id}>
          <ListItem
            alignItems="flex-start"
            onClick={() => viewJobOpening(jobOpening)}
            sx={{
              // bgcolor:'red',
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", 
                cursor: "pointer",
              },
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{bgcolor: 'primary.main'}}
                alt={jobOpening.title}
                src="/static/images/avatar/1.jpg"
              />
            </ListItemAvatar>
            <Grid container spacing={2}>
              {/* Main Job Title and Location */}
              <Grid item xs={12} sm={8}>
                <ListItemText
                  primary={
                    <Typography variant="h6">{jobOpening.title}</Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{fontSize:{xs: '0.875rem'}, color: "text.primary", display: "inline"}}
                      >
                        {`${jobOpening.business_name} / ${jobOpening.address}`}
                      </Typography>
                    </React.Fragment>
                  }
                />
                {/* Job Description */}
                <Typography
                  component="div"
                  variant="body2"
                  sx={{ color: "text.secondary", mt: 1 }}
                >
                  {`${jobOpening.job_description.substring(0,100)}...`}
                </Typography>
              </Grid>

              {/* Pay Information */}
              <Grid item xs={12} sm={4} sx={{ textAlign: {sm: "right"} }}>
                <Typography
                  component="div"
                  variant="body1"
                  sx={{ color: "text.primary" }}
                >
                  {`${jobOpening.pay_range}`}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <Divider variant="inset" component="li" sx={{ mb: 4 }} />
        </React.Fragment>
      )): <Typography  mt ={2} fontStyle={'italic'}> In Progress...</Typography>}
    </List>
  );
}
