
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import careerReducer from './slices/careerSlice';
import userReducer from './slices/userSlice';
import jobReducer from './slices/jobSlice';
import businessReducer from './slices/businessSlice';
import applicationReducer from './slices/applicationSlice';
const loggerMiddleware = (storeAPI) => (next) => (action) => {
  console.log('Dispatching action:', action);
  let result = next(action);
  console.log('Next state:', storeAPI.getState());
  return result;
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    career: careerReducer,
    user: userReducer,
    job: jobReducer,
    business: businessReducer,
    application: applicationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});

export default store;
