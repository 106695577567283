import * as React from "react";
import { Box, Drawer, Button, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function AboutDrawer() {
  const [open, setOpen] = React.useState(true);
  const { selectedPath, loading } = useSelector((state) => state.career);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{ width: { xs: "300px", sm: "400px" }, p: 2 }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      {loading ? (
        <Typography variant="h6">...</Typography>
      ) : (
        selectedPath && (
          <Box>
            {/* Overview */}
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
              Overview
            </Typography>
            <Typography variant="body2" sx={{ mb: 6 }}>
              {selectedPath.career_path_overview}
            </Typography>

            <Divider sx={{ my: 2 }} />

            {/* Industry */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Industry: {selectedPath.industry_name}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {selectedPath.industry_description}
            </Typography>

            <Divider sx={{ my: 2 }} />

            {/* Career Path Outlook */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Career Path Outlook
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {selectedPath.career_path_outlook}
            </Typography>

            <Divider sx={{ my: 2 }} />

            {/* Alignment with Career Goals */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Your Successful Career
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2,
                p: 2,
                backgroundColor: "background.main", // Subtle background color to highlight it
                borderRadius: "8px", // Rounded corners for a smooth look
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                
              }}
            >
              {selectedPath.alignment_with_career_goals}
            </Typography>

            <Divider sx={{ my: 2 }} />
          </Box>
        )
      )}
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)} variant="contained" color="primary">
        Learn More
      </Button>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
